import { ReactElement, useState, useEffect } from 'react'
import { Nfts } from '@/state/types'
import styles from './NftCard.module.scss'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useNftActionHandlers } from '@/state/nft/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const NftCard = ({
  nft,
  customClass,
  module,
  isCollection = false,
}: {
  nft: Nfts
  customClass: string
  module?: string
  isCollection?: boolean
}): ReactElement => {
  const [likeNft, setLikeNft] = useState<boolean>(false)
  const [menu, setMenu] = useState<boolean>(false)
  const router = useRouter()
  const { account } = useActiveWeb3React()
  const addedToCollection = false

  const { toggleAddCollectionDialog } = useNftActionHandlers()

  const toggleCollectionDialog = (e) => {
    e.stopPropagation()
    toggleAddCollectionDialog({
      dialog: true,
      nft: nft,
    })
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setMenu(false)
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const goToNft = () => {
    if (isCollection) {
      router.push(`/nft/collections/${nft.uuid}`)
    } else {
      router.push(`/nft/${nft.contractAddress}`)
    }
  }
  return (
    <div className={customClass}>
      <div className={`${styles.card} ${module === 'auction' && styles.card_auction}`}>
        <div>
          <div className={styles.card_image_container}>
            {module !== 'auction' && (
              <div className={styles.card_image_heart}>
                <div>
                  <Image
                    onClick={() => setLikeNft(!likeNft)}
                    layout="fill"
                    alt="like nft"
                    src={likeNft ? '/svgs/icon-heart-full.svg' : '/svgs/icon-heart-outline.svg'}
                  />
                </div>
              </div>
            )}

            <img onClick={goToNft} width={295} height={255} className={styles.card_image} src={nft.uri} alt="nft" />
          </div>
          <div onClick={goToNft}>
            {module !== 'auction' ? (
              <div>
                <div className={styles.textBody}>
                  <div>
                    <p>{nft.collectionName}</p>

                    <div className={styles.text_image}>
                      <Image
                        className="w-full"
                        src="/images/verified.png"
                        layout="fixed"
                        height={20}
                        width={20}
                        alt="verified"
                      />
                    </div>
                  </div>

                  {!isCollection && nft.ownerAddress === account && (
                    <div className={styles.card_menu_button}>
                      <Image
                        className="w-full"
                        src="/svgs/icon-menu-vertical.svg"
                        layout="fixed"
                        height={20}
                        width={20}
                        alt="verified"
                        onClick={(e) => {
                          e.stopPropagation()
                          setMenu(!menu)
                        }}
                      />
                      {menu && (
                        <div className={styles.card_menu}>
                          {addedToCollection ? (
                            <p className={styles.card_menu_inactive}>Added to a collection</p>
                          ) : (
                            <p className={styles.card_menu_active} onClick={toggleCollectionDialog}>
                              Add to a collection
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <p className={styles.textTitle}>{isCollection ? nft.quantity : nft.name}</p>
              </div>
            ) : (
              <div className={styles.card_auction_container}>
                <p className={styles.heavy_text}>{nft.name}</p>
                <p className={styles.light_text}>
                  {isCollection ? 'Floor price' : 'Price'}: {nft.price}BSC
                </p>
              </div>
            )}
          </div>
          <div
            className={`${styles.card_title_container} ${
              module === 'auction' ? styles.card_title_container_auction : ''
            }`}
          >
            <div>
              {module !== 'auction' ? (
                <div className={styles.textBody}>
                  <p> {isCollection ? 'Floor price' : 'Price'}</p>
                  <div className={styles.price_image}>
                    <Image src="/svgs/icon-bnb.svg" layout="fill" alt="ETH" />
                  </div>
                  <p className="text-white">
                    {nft.price} {!nft.money || nft.money === 0 ? 'BSC' : 'DES'}{' '}
                  </p>
                </div>
              ) : (
                <p className={styles.light_text}>Grade 1</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NftCard
