import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  cubeTestnetTokens,
  cubeTokens,
  ethereumTokens,
  mainnetTokens,
  maticTokens,
  mumbaiTokens,
  testnetTokens,
} from './tokens'

// tokens used for overview
export const DES_TOKENS = {
  [ChainId.MAINNET]: mainnetTokens.des.address, // '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
  [ChainId.MATIC]: maticTokens.des.address, // '0xa062fc09cA6bdeb2f6E3b77E1d4e09C42C964742',
  [ChainId.CUBE]: cubeTokens.des.address, // '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
  [ChainId.ETHEREUM]: ethereumTokens.des.address, // '0x634239cfA331Df0291653139d1a6083B9cf705e3',
}

export const USDT_TOKENS = {
  [ChainId.MAINNET]: mainnetTokens.usdt.address, // '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
  [ChainId.MATIC]: maticTokens.usdt.address, // '0xa062fc09cA6bdeb2f6E3b77E1d4e09C42C964742',
  [ChainId.CUBE]: cubeTokens.usdt.address, // '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
  [ChainId.ETHEREUM]: ethereumTokens.usdt.address, // '0x634239cfA331Df0291653139d1a6083B9cf705e3',
}

export const ROUTER_ADDRESS = {
  [ChainId.MAINNET]: '0xE037d12345dA559C2Ed052Bc67545e967b8639bA',
  [ChainId.TESTNET]: '0x006B33A96Ac8281eF20818D0786cA2221240F278',
  [ChainId.MATIC]: '0x275eB6da9De810b2A3072d6Bbf4CD61f2269581E',
  [ChainId.MUMBAI]: '0x275eB6da9De810b2A3072d6Bbf4CD61f2269581E',
  [ChainId.CUBE]: '0xA0EaD99F785F09CEb1607cEF27181CedCECaA4c6', // '0xE037d12345dA559C2Ed052Bc67545e967b8639bA',
  [ChainId.CUBETESTNET]: '0xE037d12345dA559C2Ed052Bc67545e967b8639bA',
  [ChainId.ETHEREUM]: '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
}

export const FACTORY_ADDRESS = {
  [ChainId.MAINNET]: '0xE4b72158459DE95E645A6c42FDe02a4d997073Bd',
  [ChainId.TESTNET]: '0x11B4994DE5aC33dEF900b19Bae4Dd2a618b9b288',
  [ChainId.MATIC]: '0xA0EaD99F785F09CEb1607cEF27181CedCECaA4c6',
  [ChainId.MUMBAI]: '0x2DA235784Cb0283219709986916DD974c454E423',
  [ChainId.CUBE]: '0x28aaF78f6D62a9C9E2335287c96b615754382d96',
  [ChainId.CUBETESTNET]: '0xFFF9780E4DaD9844E0Ef1c2e7E226366229d510d',
  [ChainId.ETHEREUM]: '0x28aaF78f6D62a9C9E2335287c96b615754382d96',
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]?: Token[]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.des,
    mainnetTokens.busd,
    mainnetTokens.usdt,
    mainnetTokens.cake,
    mainnetTokens.dai,
    mainnetTokens.btcb,
    mainnetTokens.ust,
    mainnetTokens.eth,
    mainnetTokens.usdc,
  ],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.busd, testnetTokens.cake],
  [ChainId.MATIC]: [
    maticTokens.wbnb,
    maticTokens.dai,
    maticTokens.des,
    maticTokens.busd,
    maticTokens.btc,
    maticTokens.quick,
    maticTokens.miMatic,
    mainnetTokens.usdt,
    mainnetTokens.eth,
    maticTokens.usdc,
  ],
  [ChainId.MUMBAI]: [mumbaiTokens.wbnb, mumbaiTokens.des, mumbaiTokens.eth, mumbaiTokens.dai, mumbaiTokens.usdt],
  [ChainId.CUBE]: [cubeTokens.wbnb, cubeTokens.usdt, cubeTokens.usdc],
  [ChainId.CUBETESTNET]: [cubeTestnetTokens.wbnb, cubeTestnetTokens.des, cubeTestnetTokens.busd],
  [ChainId.ETHEREUM]: [
    ethereumTokens.wbnb,
    ethereumTokens.des,
    ethereumTokens.uni,
    ethereumTokens.btc,
    ethereumTokens.dai,
    ethereumTokens.usdc,
    ethereumTokens.busd,
    ethereumTokens.usdm,
    ethereumTokens.frax,
    ethereumTokens.usdt,
  ],
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.des,
    mainnetTokens.dai,
    mainnetTokens.usdt,
    mainnetTokens.usdc,
    mainnetTokens.busd,
  ],
  [ChainId.TESTNET]: [
    testnetTokens.wbnb,
    testnetTokens.busd,
    testnetTokens.usdt,
    testnetTokens.usdc,
    testnetTokens.cake,
  ],
  [ChainId.MATIC]: [
    maticTokens.wbnb,
    maticTokens.des,
    maticTokens.dai,
    maticTokens.usdt,
    maticTokens.usdc,
    maticTokens.btc,
  ],
  [ChainId.MUMBAI]: [mumbaiTokens.wbnb, mumbaiTokens.dai, mumbaiTokens.usdt, mumbaiTokens.eth],
  [ChainId.CUBE]: [cubeTokens.wbnb, cubeTokens.des, cubeTokens.usdt, cubeTokens.usdc],
  [ChainId.CUBETESTNET]: [
    cubeTestnetTokens.wbnb,
    cubeTestnetTokens.busd,
    cubeTestnetTokens.usdt,
    cubeTestnetTokens.usdc,
  ],
  [ChainId.ETHEREUM]: [
    ethereumTokens.wbnb,
    ethereumTokens.des,
    ethereumTokens.dai,
    ethereumTokens.usdt,
    ethereumTokens.usdc,
    ethereumTokens.btc,
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.busd,
    mainnetTokens.des,
    mainnetTokens.dai,
    mainnetTokens.eth,
    mainnetTokens.usdt,
    mainnetTokens.usdc,
    mainnetTokens.btcb,
    mainnetTokens.lead,
  ],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.busd, testnetTokens.usdt, testnetTokens.usdc],
  [ChainId.MATIC]: [
    maticTokens.wbnb,
    maticTokens.busd,
    maticTokens.dai,
    maticTokens.eth,
    maticTokens.usdt,
    maticTokens.usdc,
    maticTokens.btc,
  ],
  [ChainId.MUMBAI]: [mumbaiTokens.wbnb, mumbaiTokens.dai, mumbaiTokens.usdt, mumbaiTokens.eth],
  [ChainId.CUBE]: [cubeTokens.wbnb, cubeTokens.des, cubeTokens.usdt, cubeTokens.usdc],
  [ChainId.CUBETESTNET]: [
    cubeTestnetTokens.wbnb,
    cubeTestnetTokens.busd,
    cubeTestnetTokens.usdt,
    cubeTestnetTokens.usdc,
  ],
  [ChainId.ETHEREUM]: [
    ethereumTokens.wbnb,
    ethereumTokens.busd,
    ethereumTokens.des,
    ethereumTokens.dai,
    ethereumTokens.usdt,
    ethereumTokens.usdc,
    ethereumTokens.btc,
  ],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [mainnetTokens.des, mainnetTokens.usdt],
    [mainnetTokens.busd, mainnetTokens.usdt],
    [mainnetTokens.dai, mainnetTokens.usdt],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
]

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

// Gelato uses this address to define a native currency in all chains
export const GELATO_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const EXCHANGE_DOCS_URLS = 'https://docs.despace.app/despace'
export const LIMIT_ORDERS_DOCS_URL = 'https://docs.despace.app/despace'
