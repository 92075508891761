import Image from 'next/image'
import React, { useEffect } from 'react'

import styles from './TableFooter.module.scss'

const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1)
    }
  }, [slice, page, setPage])
  return (
    <div className={styles.tableFooter}>
      <div
        className={`${styles.pagination_left} ${page === 1 && styles.dull}`}
        onClick={() => {
          if (page > 1) {
            setPage(page - 1)
          }
        }}
      >
        <Image src="/svgs/arrow-left.svg" layout="fill" alt="" />
      </div>
      {/* {range.map((el, index) => (
        <button
          key={index}
          className={`${styles.button} ${page === el ? styles.activeButton : styles.inactiveButton}`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))} */}
      <div className={styles.text}>
        <p>
          Page {page} of {range.length}
        </p>
      </div>
      <div
        className={`${styles.pagination_right} ${page === range.length && styles.dull}`}
        onClick={() => {
          if (page < range.length) {
            setPage(page + 1)
          }
        }}
      >
        <Image src="/svgs/arrow-left.svg" layout="fill" alt="" />
      </div>
    </div>
  )
}

export default TableFooter
