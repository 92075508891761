import React, { useState, useEffect } from 'react'
import styles from './Logo.module.scss'

import Image from 'next/image'
import { useGlobalContext } from '@/contexts/AppContext'

interface Props {
  type?: 'default' | 'mob' | 'mixed-light' | 'mixed-dark'
}

const Logo = ({ type = 'default' }: Props) => {
  const { collapseSidebar }: any = useGlobalContext()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
    const checkResize = () => {
      if (window.innerWidth <= 1023) {
        type === 'mob'
        setIsMobile(true)
      }
      if (window.innerWidth > 1023) {
        type === 'default'
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', checkResize, { passive: true })
    checkResize()
    return () => window.removeEventListener('resize', checkResize)
  }, [])
  return (
    <div className={styles.logo} data-collapsed={collapseSidebar}>
      <Image
        src={type === 'mob' || isMobile ? '/svgs/logo-mob.svg' : '/svgs/logo.svg'}
        layout="fill"
        loading="eager"
        priority={true}
        quality={100}
        alt="deswap"
      />
    </div>
  )
}

export default Logo
