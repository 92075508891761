import { ChainId } from '@pancakeswap/sdk'

// used to mark unsupported tokens, these are hosted lists of unsupported tokens

const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
const QUICK_SWAP = 'https://unpkg.com/quickswap-default-token-list@1.0.67/build/quickswap-default.tokenlist.json'
const PANCAKE_EXTENDED = 'https://tokens.pancakeswap.finance/pancakeswap-extended.json'
const PANCAKE_TOP100 = 'https://tokens.pancakeswap.finance/pancakeswap-top-100.json'
const COINGECKO = 'https://tokens.pancakeswap.finance/coingecko.json'
const CMC = 'https://tokens.pancakeswap.finance/cmc.json'
const DESWAP_LIST = 'https://raw.githubusercontent.com/DeSpaceDeFi/tokenLists/cubeChain/tokens.json';

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]
export const BYPASS_LIST = []
export const OFFICIAL_LISTS = [PANCAKE_TOP100, PANCAKE_EXTENDED, DESWAP_LIST, QUICK_SWAP]

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  COINGECKO,
  CMC,
  DESWAP_LIST,
  GEMINI_LIST,
  QUICK_SWAP,
  PANCAKE_TOP100,
  PANCAKE_EXTENDED,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []

export const HIDE_LIST = [COINGECKO]
