import { Button } from '@/shared'
import Image from 'next/image'
import React from 'react'
import styles from "./SecondaryCard.module.scss"

interface Props {
  token1?: any;
  token2?: any;
  liquidity?: number;
  children?: React.ReactNode;
  cardType?: "Pool" | "LP";
  click?: (e?: any) => void;
}

const SecondaryCard = ({ cardType = "LP", token1, token2, liquidity, children, click} : Props) => {
  return (
    <div className={styles.secondaryCard}>
      <div className={styles.secondaryCard_childrenDesk}>
          {children}
      </div>
    </div>
  )
}

export default SecondaryCard