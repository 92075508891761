import React from 'react';
import Image from 'next/image';
import styles from './ConnectWallet.module.scss';
import UserMenu from '@/components/Menu/UserMenu';

const ConnectWallet = () => {
	return (
		<>
			<UserMenu />
		</>
	);
};

export default ConnectWallet;
