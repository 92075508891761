export interface Chains {
    label: string;
    icon: string;
  }

  export const chains: Chains[] = [
    {
        label: "polygon",
        icon: "/svgs/polygon.svg",
    },
    {
        label: "arbitrum",
        icon: "/svgs/arbitrum.svg",
    },
    {
        label: "avalanche",
        icon: "/svgs/avalanche.svg",
    },
    {
        label: "BSC",
        icon: "/svgs/bsc.svg",
    },
    {
        label: "ethereum",
        icon: "/svgs/ethereum.svg",
    },
    {
        label: "fantom",
        icon: "/svgs/fantom.svg",
    },
  ]