import React, { useState, useEffect } from 'react';
import { Button, InputField } from '@/shared';
import { slippage } from '@/mock';
import Image from 'next/image';
import styles from './SettingsModal.module.scss';
import { gas } from '@/mock/settings';
import { useGlobalContext } from '@/contexts/AppContext';

interface DropDown {
	slippage: boolean;
	gas: boolean;
}
export type Props = {
	close: () => void;
};

const SettingsModal = ({ close }: Props) => {
	const {
		slippageValue,
		setSlippageValue,
		isCustomSlippage,
		setIsCustomSlippage,
		gasValue,
		setGasValue,
		isSettingsActive,
		setIsSettingsActive,
	}: any = useGlobalContext();
	const [isDropDown, setIsDropDown] = useState<DropDown>({
		slippage: false,
		gas: false,
	});

	useEffect(() => {
		const handleClickOutside = () => {
			close();
		};

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [close]);

	const handleSlippage = (value: number | string, id: number) => {
		setIsSettingsActive({ ...isSettingsActive, slippageActive: id });
		if (typeof value != 'string') {
			setSlippageValue(value);
		} else {
			setIsCustomSlippage(true);
		}
	};

	const handleGas = (value: number, id: number, label: string) => {
		const gas = `${label} (${value} Gwei)`;
		setIsSettingsActive({ ...isSettingsActive, gasActive: id });
		setGasValue(gas);
	};

	return (
		<div className={styles.settingsModal}>
			<div
				className={styles.settingsModal_container}
				onClick={e => e.stopPropagation()}
			>
				<div className={styles.settingsModal_row}>
					<h3 className={styles.settingsModal_title}>Advanced Settings</h3>
					<Button
						className={styles.settingsModal_closeModal}
						type="transparent"
						onClick={close}
					>
						<span></span>
						<span></span>
					</Button>
				</div>
				<div className={styles.settingsModal_section}>
					<div
						className={styles.settingsModal_header}
						onClick={() =>
							setIsDropDown({
								...isDropDown,
								slippage: !isDropDown.slippage,
							})
						}
					>
						<h3>Slippage Tolerance</h3>
						<div className={styles.settingsModal_headerDetails}>
							<h3>{slippageValue}%</h3>
							<div className={styles.settings_dropDown}>
								<Image
									src="/svgs/select-drop-down.svg"
									alt=""
									layout="fill"
								/>
							</div>
						</div>
					</div>
					<div
						className={styles.settingsModal_body}
						aria-expanded={isDropDown.slippage}
					>
						{!isCustomSlippage ? (
							slippage.map(({ value, id }, index) => {
								return (
									<div
										key={index}
										className={`${styles.settingsModal_btn} ${
											isSettingsActive.slippageActive === index
												? styles.settingsModal_btnActive
												: ''
										}`}
										onClick={() => handleSlippage(value, id)}
									>
										{value}
										{value !== 'custom' ? '%' : ''}
									</div>
								);
							})
						) : (
							<div className={styles.settingsModal_inputContainer}>
								<InputField
									type="number"
									className={styles.settingsModal_inputBox}
									onChange={e => setSlippageValue(e.target.value)}
								/>
								<div
									className={`${styles.settingsModal_btn}`}
									onClick={() => setIsCustomSlippage(false)}
								>
									Done
								</div>
							</div>
						)}
					</div>
				</div>
				<div className={styles.settingsModal_section}>
					<div
						className={styles.settingsModal_header}
						onClick={() =>
							setIsDropDown({ ...isDropDown, gas: !isDropDown.gas })
						}
					>
						<h3>Gas Price</h3>
						<div className={styles.settingsModal_headerDetails}>
							<h3>{gasValue}</h3>
							<div className={styles.settings_dropDown}>
								<Image
									src="/svgs/select-drop-down.svg"
									alt=""
									layout="fill"
								/>
							</div>
						</div>
					</div>
					<div
						className={styles.settingsModal_body}
						aria-expanded={isDropDown.gas}
					>
						{gas.map(({ value, id, label }, index) => {
							return (
								<div
									key={index}
									className={`${styles.settingsModal_btn} ${
										isSettingsActive.gasActive === index
											? styles.settingsModal_btnActive
											: ''
									}`}
									onClick={() => handleGas(value, id, label)}
								>
									{value} {label}
								</div>
							);
						})}
					</div>
				</div>
				<div className={styles.settingsModal_section}>
					<div className={styles.settingsModal_header}>
						<h3>Spread Across DEXes</h3>
						<div className={styles.settingsModal_headerDetails}>
							<h3>10 Selected</h3>
							<div className={styles.settings_dropDown}>
								<Image
									src="/svgs/select-drop-down.svg"
									alt=""
									layout="fill"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsModal;
