import { NextRouter } from 'next/router'

export const addUser = async (address: string) => {
  try {
    const newUserResponse = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/users`, {
      method: 'POST',
      body: JSON.stringify({
        address,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    return await newUserResponse.json()
  } catch (error) {
    console.log(error)
  }
}

export const storeRefId = async (router: NextRouter) => {
  const { ref } = router.query
  if (ref && typeof ref === 'string') {
    if (typeof window !== 'undefined') {
      localStorage.setItem('refID', ref)
    }
  }
}

export const getTableData = async (address: string) => {
  const refUrls = [
    `${process.env.NEXT_PUBLIC_API_URL}/referrals/stats?address=${address}`,
    `${process.env.NEXT_PUBLIC_API_URL}/referrals?address=${address}`,
  ]
  const refRequests = refUrls.map((url) => fetch(url))
  try {
    const [statResponse, referralResponse] = await Promise.all(refRequests)
    return { stat: await statResponse.json(), referral: await referralResponse.json() }
  } catch (error) {
    console.log(error)
  }
}

export const getReferralData = async (account: string) => {
  const userApiUrl = `${process.env.NEXT_PUBLIC_API_URL}/users/${account}`
  let userResponse = {}

  try {
    const checkUserResponse = await fetch(userApiUrl)
    userResponse = await checkUserResponse.json()
    console.log('user referral response ===> ', userResponse)

    if ((userResponse as any).status === 404) {
      userResponse = await addUser(account)
    }
    const { stat, referral } = await getTableData(account)
    return [stat, referral, userResponse]
  } catch (error: any) {
    if (error.status === 404) {
      try {
        userResponse = await addUser(account)
        const { stat, referral } = await getTableData(account)
        return [stat, referral, userResponse]
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log('error, all apis failed', error)
    }
  }
}

export const addReferralCommission = async ({
  recipient,
  inputs,
  outputs,
  amount,
  chainId,
}: {
  amount: number
  chainId: number
  recipient: string
  inputs: {
    address: string
    symbol: string
    amount: string
  }
  outputs: {
    address: string
    symbol: string
    amount: string
  }
}): Promise<void> => {
  fetch(`${process.env.NEXT_PUBLIC_API_URL}/referrals/commission`, {
    method: 'POST',
    body: JSON.stringify({
      referralId: localStorage.getItem('refID'),
      referredAddress: recipient,
      inputs,
      outputs,
      amount,
      chainId,
      symbol: inputs.symbol,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
    .then((json) => console.log(json))
}

export const addUserReferral = async (referredAddress: string): Promise<void> => {
  try {
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/referrals`, {
      method: 'POST',
      body: JSON.stringify({
        referralId: localStorage.getItem('refID'),
        referredAddress,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => console.log(json))
  } catch (error) {
    throw new Error('something went wrong')
  }
}
