import { DES_TOKENS } from "@/config/constants";
import { CHAIN_ID } from "@/config/constants/networks";

const getTokenLogoURL = (address: string) => {
  // Override URL for specific tokens here
  if (address === DES_TOKENS[CHAIN_ID]) return "/des.png";
  if (address === "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6") return "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png";
  return getTokenLogoUriByChain(address);
}

function getTokenLogoUriByChain(address: string): string  {
  if (['137', '80001'].includes(CHAIN_ID)) return `https://assets.trustwalletapp.com/blockchains/polygon/assets/${address}/logo.png`;
  if (['56', '97'].includes(CHAIN_ID)) return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`;
  if (['1818', '1819'].includes(CHAIN_ID)) return `https://raw.githubusercontent.com/DeSpaceDeFi/tokenLists/cubeChain/images/${address}.png`
  if (['1', '3', '4'].includes(CHAIN_ID)) return `https://assets.trustwalletapp.com/blockchains/ethereum/assets/${address}/logo.png`
}

export default getTokenLogoURL
