import styles from './CreateCollectionModal.module.scss'
import { NftFormSelect } from '@/shared/selects'
import Image from 'next/image'
import { CustomInput, TextArea } from '@/shared'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCreateCollection } from '@/state/nft/hooks'
import { SUPPORTED_CHAIN_IDS, NETWORK_DETAILS } from '@/config'
import { apiCall } from '@/state/nft/helpers'

const CreateCollectionModal = ({ hideDialog }: { hideDialog: () => void }) => {
  const { account } = useActiveWeb3React()
  const {
    loading,
    createCollection,
    values,
    setValues,
    errors,
    setErrors,
    validate,
    checkFormValidity,
    hasError,
    handleChange,
    handleFileChange,
  } = useCreateCollection()

  const networkOptions = SUPPORTED_CHAIN_IDS.map((chainId) => {
    return {
      text: NETWORK_DETAILS[chainId].label,
      value: chainId,
      icon: NETWORK_DETAILS[chainId].icon,
      disabled: chainId !== 97 && chainId !== 56 && chainId !== 137,
    }
  })

  const handlePrepend = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/check-short-url`

    validate(e)
    const checkUrl = await apiCall(apiUrl, 'POST', { shortUrl: e.target.value })
    console.log(checkUrl.message, `Check url`)

    if (checkUrl.message === 'Short url available to use.') {
      setValues({ ...values, shortUrl: e.target.value })
    } else {
      setErrors({
        ...errors,
        shortUrl: 'Url already taken',
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (hasError()) {
      return
    }
    const payload = {
      ...values,
      userAddress: account,
      network: NETWORK_DETAILS[values.chainId].label,
      bannerUrl: '',
      profileUrl: '',
      featuredUrl: `https://despace.app/nfts/collections/${values.shortUrl}`,
    }

    const created = await createCollection(payload)
    if (created) {
      hideDialog()
    }
  }

  return (
    <div className={styles.create_collection}>
      <div className={styles.create_collection_container}>
        <div className={styles.create_collection_heading}>
          <div>
            <h6>Create Collection</h6>
          </div>
          <button className={styles.create_collection_container_close} onClick={() => hideDialog()}>
            <Image layout="fill" src="/svgs/icon-close.svg" alt="close" />
          </button>
        </div>
        <div className={styles.create_collection_form}>
          <label htmlFor="">
            Upload File <span>*</span>
          </label>
          <div className={styles.create_collection_upload}>
            <div className={styles.create_collection_preview}>
              {values.image && <Image layout="fill" src={values.image} alt="close" />}
            </div>
            <div>
              <p>At least 300 x 300 pixels. Maximum size of 6MB, JPG, WEBP, GIF, MP3 or MP4</p>
              <input type="file" accept="image/*" className="custom-file-input" onChange={handleFileChange} />
            </div>
          </div>

          <CustomInput
            label="Name"
            placeholder="Collection Name"
            name="name"
            required={true}
            onBlur={validate}
            className={styles.form_spacing}
            hasError={errors.name ? true : false}
            hint={errors.name}
            onChange={handleChange}
          />
          <TextArea
            label="Description"
            name="description"
            placeholder="Provide a detailed description of your item"
            className={styles.form_spacing}
            small={true}
            onChange={handleChange}
          />
          <NftFormSelect
            items={networkOptions}
            label="Network"
            placeholder="Select Network"
            className={styles.form_spacing}
            name="chainId"
            onChange={handleChange}
          />
          <CustomInput
            label="Short Url"
            prepend="https://despace.app/nfts/collections/"
            name="shortUrl"
            required={true}
            onBlur={handlePrepend}
            placeholder=""
            className={styles.form_spacing}
            hint={errors.shortUrl || 'Will be used as public URL'}
            hasError={errors.shortUrl ? true : false}
            onChange={handleChange}
          />
          <button
            disabled={!checkFormValidity() || loading}
            onClick={handleSubmit}
            type="submit"
            className={styles.create_collection_action_button}
          >
            {loading ? <div className="loading-spinner" /> : 'Create Collection'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateCollectionModal
