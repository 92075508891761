import { useTranslation } from 'contexts/Localization'
import { Button, Text, Link, HelpIcon } from '@pancakeswap/uikit'
import { setupNetworkWithChain } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { NETWORK_DETAILS, SUPPORTED_CHAIN_IDS } from '@/config'
import { ChainId } from '@pancakeswap/sdk'
import styles from './Styles.module.scss';

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { library } = useWeb3React()

  const handleSwitchNetwork = async (chainId: ChainId): Promise<void> => {
    await setupNetworkWithChain(library, chainId)
    onDismiss?.()
  }

  return (
    <>
      <Text mb="2.4rem">{t('You’re connected to the wrong network. Please connect to the appropriate network below.')}</Text>
      <div className={styles.network_list}>
        {SUPPORTED_CHAIN_IDS.map((key: ChainId, i: number) => {
          return (
            <div className={styles.select_networkButton}
              title={NETWORK_DETAILS[key].label}
              key={i}
              onClick={() => {
                handleSwitchNetwork(key)
              }}
            >
              <div className={styles.list_item}>
                <img src={NETWORK_DETAILS[key].icon} alt="Switch Network" className={styles.network_icon} />
                <div className={styles.network_label}>{NETWORK_DETAILS[key].label}</div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default WalletWrongNetwork
