import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Logo } from '@/shared'
import { navLinks, socials } from '@/mock'
import Image from 'next/image'
import styles from './SideBar.module.scss'
import { useGlobalContext } from '@/contexts/AppContext'

interface Props {
  transparent: boolean
}

const SideBar = ({ transparent }: Props) => {
  const { collapseMobHeader, setCollapseMobHeader, collapseSidebar, setCollapseSidebar }: any = useGlobalContext()
  const [showMore, setShowMore] = useState<boolean>(false)
  const router = useRouter()

  const toggleSideBar = () => {
    setCollapseSidebar((collapseSidebar: boolean) => !collapseSidebar)
  }

  const closeMore = () => {
    setShowMore(false)
    setCollapseMobHeader(true)
  }
  const closeSideBar = (id: string) => {
    setCollapseMobHeader(true)
  }
  const checkActive = (url: string) => {
    let isActive = url === location.pathname
    if (
      url === '/swap' &&
      ['/liquidity', '/addLiquidity', '/add', '/find', '/removeLiquidity', 'remove', '/limit-orders'].includes(location.pathname)
    )
      isActive = true

    if (url === '/' && location.pathname.includes('/info')) isActive = true

    // if (
    //   url === '/swap' &&
    //   (location.pathname.includes('/add') ||
    //     location.pathname.includes('/remove') ||
    //     location.pathname.includes('/find') ||
    //     location.pathname.includes('/limit-orders'))
    // )
    //   isActive = true
    return isActive
  }

  useEffect(() => {
    const checkResize = () => {
      if (window.innerWidth <= 1023) {
        setCollapseSidebar(false)
      }
    }
    window.addEventListener('resize', checkResize, { passive: true })
    checkResize()
    return () => window.removeEventListener('resize', checkResize)
  }, [])

  useEffect(() => {
    collapseMobHeader ? (document.body.style.overflow = 'auto') : (document.body.style.overflow = 'hidden')
  }, [collapseMobHeader])

  return (
    <div data-transparent={transparent} className={styles.sidebar}>
      <Link href="/">
        <a className={styles.sidebar_logo}>
          <div>
            <Logo type={collapseSidebar ? 'mob' : 'default'} />
          </div>
        </a>
      </Link>
      <div className={styles.sidebar_toggle} onClick={toggleSideBar} data-collapsed={collapseSidebar}>
        <div className={styles.sidebar_toggle_icon}>
          <Image src="/svgs/fast-arrow.svg" layout="fill" alt="" loading="eager" priority={true} />
        </div>
      </div>
      <div
        className={styles[!collapseMobHeader ? 'sidebar_wrapper' : 'sidebar_wrapper__collapsed']}
        data-collapsed={collapseSidebar}
      >
        <nav className={styles.sidebar_nav}>
          <ul className={styles.sidebar_menu}>
            {navLinks.map(({ name, url, external, disabled, icon, iconActive }, index) => {
              return (
                <li
                  key={index}
                  className={`${styles.sidebar_menuItem} ${disabled && styles.sidebar_menuItem__disabled}`}
                  data-active={checkActive(url)}
                  onClick={() => closeSideBar(name)}
                  data-collapsed={collapseSidebar}
                >
                  <div
                    className={styles.sidebar_icon}
                    onClick={() => {
                      !disabled ? router.push(url) : null
                    }}
                  >
                    <Image
                      src={checkActive(url) ? iconActive : icon}
                      alt=""
                      layout="fill"
                      title={name}
                      loading="eager"
                      priority={true}
                    />
                  </div>

                  {disabled ? (
                    <p>{name}</p>
                  ) : external ? (
                    <a href={url} target="_blank">
                      <a>{name}</a>
                    </a>
                  ) : (
                    <Link href={url}>
                      <a>{name}</a>
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
        <div className={styles.footer} data-collapsed={collapseSidebar}>
          <a href="https://docs.despace.app" target="_blank" rel="noreferrer" className={styles.footer_icon_container}>
            <div className={styles.footer_icon}>
              <Image src="/svgs/icon-info.svg" layout="fill" alt="" title="Docs" loading="eager" priority={true} />
            </div>
          </a>
          <div className={styles.footer_more} onClick={() => setShowMore((showMore: boolean) => !showMore)}>
            <div className={styles.footer_icon}>
              <Image src="/svgs/icon-menu.svg" layout="fill" alt="" title="More" loading="eager" priority={true} />
            </div>
          </div>
          {showMore && (
            <div className={styles.detail_list} onClick={closeMore}>
              {socials.map(({ icon, title, url }, index) => {
                return (
                  <a href={url} target="_blank" rel="noreferrer" className={styles.detail} key={index}>
                    <div className={styles.social_icon}>
                      <Image src={icon} layout="fill" alt="" title={title} />
                    </div>
                    <p>{title}</p>
                  </a>
                )
              })}
            </div>
          )}
        </div>
      </div>

      {/* <div
				onClick={() => setCollapsed(!collapsed)}
				className={
					styles[collapsed ? 'header_hamburger' : 'header_hamburger__open']
				}
			>
				<span className={styles.sidebar_hamburgerBar}></span>
				<span className={styles.sidebar_hamburgerBar}></span>
			</div> */}
    </div>
  )
}

export default SideBar
