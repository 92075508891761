import Image from 'next/image'

interface Props {
  icon?: string
  placeholder?: string
  value?: string | number
  name?: string
  label?: string
  className?: string
  required?: boolean
  description?: string
  small?: boolean
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
}

const TextArea = ({
  name,
  icon,
  label,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  className,
  required,
  description,
  small = false,
}: Props) => {
  return (
    <div className={`input ${className}`}>
      {!!label && (
        <label className="input_label" htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
      )}

      {!!description && <p className="input_description">{description}</p>}

      <div className="input_wrapper">
        {!!icon && (
          <figure className="input_icon">
            <Image src={icon} layout="fill" alt="icon" />
          </figure>
        )}
        <textarea
          name={name}
          className={`input_field ${small ? 'text_field_small' : ''}`}
          data-icon={!!icon}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
      </div>
    </div>
  )
}

export default TextArea
