export interface Tokens {
    label: string;
    name: string;
    icon: string;
    balance: number;
  }

  export const tokens: Tokens[] = [
    {
        label: "USDT",
        name: "Tether USD",
        icon: "/svgs/usdt.svg",
        balance: 400,
    },
    {
        label: "DES",
        name: "DeSpace Protocol",
        icon: "/des.png",
        balance: 600,
    },
      {
          label: "ETH",
          icon: "/svgs/ethereum.svg",
          name: "Ethereum",
          balance: 300,
      },
    {
        label: "FTM",
        name: "Fantom",
        icon: "/svgs/ftm.svg",
        balance: 500,
    },
    {
        label: "BNB",
        name: "Binance Coin",
        icon: "/svgs/bsc.svg",
        balance: 700,
    },
    {
        label: "AVAX",
        name: "Avalanche Coin",
        icon: "/svgs/avalanche.svg",
        balance: 800,
    },
    {
        label: "MATIC",
        name: "Matic",
        icon: "/svgs/polygon.svg",
        balance: 900,
    },
  ]

 