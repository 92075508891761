export interface NavLink {
  name: string
  title: string
  url: string
  external?: boolean
  disabled?: boolean
  isDropDown?: boolean
  id: number
  icon: string
  iconActive: string
}

interface Socials {
  icon: string
  title: string
  url: string
}

export const navLinks: NavLink[] = [
  {
    name: 'Overview',
    title: 'overview',
    url: '/',
    external: false,
    id: 0,
    icon: '/svgs/icon-overview.svg',
    iconActive: '/svgs/icon-overview-active.svg',
  },
  {
    name: 'DeSwap',
    title: 'deSwap',
    url: '/swap',
    external: false,
    id: 1,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },
  {
    name: 'Farm',
    title: 'farm',
    url: '/farms',
    external: false,
    disabled: true,
    id: 2,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },
  {
    name: 'Migrate',
    title: 'Liquidity Migration',
    url: '/migrate',
    external: false,
    disabled: false,
    id: 3,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },

  {
    name: 'Referral',
    title: 'referral',
    url: '/referral',
    external: false,
    disabled: false,
    id: 4,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },
  {
    name: 'Staking',
    title: 'staking',
    url: '/staking',
    external: false,
    disabled: false,
    id: 5,
    icon: '/svgs/icon-staking.svg',
    iconActive: '/svgs/icon-staking.svg',
  },
  {
    name: 'D-Aggregator',
    title: 'aggregator',
    url: '/defi-aggregator',
    external: false,
    disabled: true,
    id: 10,
    icon: '/svgs/icon-aggregator.svg',
    iconActive: '/svgs/icon-aggregator.svg',
  },
  {
    name: 'Auction',
    title: 'auction',
    url: 'https://auction.despace.app',
    external: true,
    disabled: true,
    id: 6,
    icon: '/svgs/icon-auction.svg',
    iconActive: '/svgs/icon-auction.svg',
  },
  {
    name: 'NFT MarketPlace',
    title: 'nft',
    url: '/nft',
    external: false,
    disabled: true,
    id: 7,
    icon: '/svgs/icon-nft.svg',
    iconActive: '/svgs/icon-nft.svg',
  },
  {
    name: 'Lottery',
    title: 'lottery',
    url: 'https://lottery.despace.app',
    external: true,
    disabled: true,
    id: 7,
    icon: '/svgs/icon-lottery.svg',
    iconActive: '/svgs/icon-lottery.svg',
  },
  {
    name: 'D-Bridge',
    title: 'd-bridge',
    url: 'https://bridge.despace.app',
    external: true,
    disabled: true,
    id: 8,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },
  {
    name: 'D-Pools',
    title: 'd-pools',
    url: '/pools',
    external: false,
    disabled: true,
    id: 9,
    icon: '/svgs/icon-sidebar.svg',
    iconActive: '/svgs/icon-sidebar-active.svg',
  },
  {
    name: 'Claim',
    title: 'claim',
    url: '/claim',
    external: false,
    disabled: true,
    id: 10,
    icon: '/svgs/icon-aggregator.svg',
    iconActive: '/svgs/icon-aggregator.svg',
  },
]

export const socials: Socials[] = [
  {
    icon: '/svgs/privacy.svg',
    title: 'Privacy Policy',
    url: 'https://despace.io/privacy-policy',
  },
  {
    icon: '/svgs/terms.svg',
    title: 'Terms and Conditions',
    url: 'https://despace.io/terms',
  },
  {
    icon: '/svgs/team.svg',
    title: 'Team',
    url: 'https://despace.io/about',
  },
  {
    icon: '/svgs/github.svg',
    title: 'Github',
    url: 'https://github.com/DeSpaceDeFi',
  },
  {
    icon: '/svgs/twitter.svg',
    title: 'Twitter',
    url: 'https://twitter.com/DespaceDefi',
  },
  {
    icon: '/svgs/telegram.svg',
    title: 'Telegram',
    url: 'https://t.me/despacegroup',
  },
]
