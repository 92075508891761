import { Button } from '@/shared'
import React, { useState, useEffect, useCallback } from 'react'
import styles from './ChainSelect.module.scss'
import Image from 'next/image'
import SmallLoader from '@/shared/loaders/smallLoader/SmallLoader'
import { setupNetworkWithChain } from '@/utils/wallet'
import { Chains } from '@/mock/chains'
import { connectorsByName } from '@/utils/web3React'
import { connectorLocalStorageKey } from '@pancakeswap/uikit'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { CHAIN_ID } from '@/config/constants/networks'
import { ChainId as CHAIN_ID_CHECK } from '@pancakeswap/sdk'
import { NETWORK_DETAILS, SUPPORTED_CHAIN_IDS } from '@/config'
import isWindow from '@/utils/isWindow'
import { useRouter } from 'next/router'

export interface BridgeSelectProps {
  options: Chains[]
  className?: string
}

const BridgeSelect: React.FunctionComponent<BridgeSelectProps> = ({ options, className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedChainId, setSelectedChainId] = useState<number>(parseInt(CHAIN_ID))
  const { chainId } = useActiveWeb3React()

  // get networkId Query
  const router = useRouter()
  const validChainIds = Object.keys(CHAIN_ID_CHECK).map(function (type) {
    return CHAIN_ID_CHECK[type]
  })

  const networkId = router.query.networkId

  useEffect(() => {
    if (chainId) {
      setSelectedChainId(chainId)
    }
  }, [chainId])

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }

  useEffect(() => {
    if (
      networkId &&
      typeof networkId === 'string' &&
      validChainIds.includes(parseInt(networkId)) &&
      !sessionStorage.getItem('networkId')
    ) {
      onOptionClicked(parseInt(networkId))()

      // add the networkId param to session storage so that this useEffect doesnt try to switch the user back after they have been switched the first time
      // Unless they open a new tab
      sessionStorage.setItem('networkId', networkId)
    }
  }, [networkId])

  const onOptionClicked = (_selectedChainId: number) => async () => {
    setSelectedChainId(_selectedChainId)
    setIsOpen(false)
    if (isWindow()) {
      const connectorID = window.localStorage.getItem(connectorLocalStorageKey) || 'injected'
      const connectorOrGetConnector = connectorsByName[connectorID]
      const connector =
        typeof connectorOrGetConnector !== 'function' ? connectorsByName[connectorID] : await connectorOrGetConnector()
      const provider = typeof connector != 'function' && connector ? await connector.getProvider() : window.ethereum
      await setupNetworkWithChain(provider, _selectedChainId)
    } else {
      await setupNetworkWithChain(null, _selectedChainId)
    }
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={`${styles.select} ${className}`}>
      {!options ? (
        <SmallLoader />
      ) : (
        <Button className={styles.select_header} type="transparent" onClick={toggling}>
          <div className={styles.select_smallRow} style={{ justifyContent: 'flex-start' }}>
            <div className={styles.select_tokenImage}>
              <Image src={NETWORK_DETAILS[selectedChainId].icon} layout="fill" alt="" />
            </div>
            <p>{NETWORK_DETAILS[selectedChainId].label}</p>
            {/* <div className={styles.select_dropDownImage}> */}
            {/* <Image src="/svgs/drop-down.svg" layout="fill" alt="" /> */}
            <svg
              viewBox="0 0 24 24"
              color="text"
              width="2.4rem"
              xmlns="http://www.w3.org/2000/svg"
              className={`Svg-sc-c89520a0-0 gdszHo ${styles.dropDown}`}
            >
              <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
            </svg>
            {/* </div> */}
          </div>
        </Button>
      )}

      {isOpen && (
        <div className={styles.select_body}>
          <ul className={styles.select_listContainer}>
            {SUPPORTED_CHAIN_IDS.map((chainId, index) =>
              chainId !== selectedChainId ? (
                <li onClick={onOptionClicked(chainId)} key={index} className={styles.select_listItem}>
                  <Button type="transparent" className={styles.select_row}>
                    <div className={styles.select_tokenImage}>
                      <Image src={NETWORK_DETAILS[chainId].icon} layout="fill" alt="" />
                    </div>
                    <p>{NETWORK_DETAILS[chainId].label}</p>
                  </Button>
                </li>
              ) : null,
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default BridgeSelect
