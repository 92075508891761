// Set of helper functions to facilitate wallet setup

import { ExternalProvider, Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@pancakeswap/sdk'
import { BAD_SRCS } from 'components/Logo/Logo'
import { BASE_RPC_SCAN_URLS, CHAIN_ID_KEY, NETWORK_URLS } from 'config'
import { nodes } from './getRpcUrl'
import isWindow from './isWindow'
import store from 'state'
import getChainID from './getChainID'

const NETWORK_CONFIG = {
  [ChainId.MAINNET]: {
    name: 'BNB Smart Chain Mainnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.MAINNET],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    }
  },
  [ChainId.TESTNET]: {
    name: 'BNB Smart Chain Testnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.TESTNET],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    }
  },
  [ChainId.MATIC]: {
    name: 'Polygon Matic Mainnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.MATIC],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    }
  },
  [ChainId.MUMBAI]: {
    name: 'Polygon Mumbai Testnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.MUMBAI],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    }
  },
  [ChainId.CUBE]: {
    name: 'CUBE Mainnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.CUBE],
    nativeCurrency: {
      name: 'CUBE',
      symbol: 'CUBE',
      decimals: 18,
    }
  },
  [ChainId.CUBETESTNET]: {
    name: 'CUBE Testnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.CUBETESTNET],
    nativeCurrency: {
      name: 'CUBE',
      symbol: 'CUBE',
      decimals: 18,
    }
  },
  [ChainId.ETHEREUM]: {
    name: 'Ethereum Mainnet',
    scanURL: BASE_RPC_SCAN_URLS[ChainId.ETHEREUM],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    }
  },
}

/**
 * Prompt the user to add select chain as a network on Metamask, or switch to the chain if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum
  const chainId = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID, 10) as keyof typeof NETWORK_CONFIG
  if (!NETWORK_CONFIG[chainId]) {
    console.error('Invalid chain id')
    return false
  }
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return true
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: NETWORK_CONFIG[chainId].nativeCurrency,
                rpcUrls: NETWORK_URLS[chainId],
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error)
          return false
        }
      }
      return false
    }
  } else {
    console.error("Can't setup network on metamask because window.ethereum is undefined")
    return false
  }
}

export const setupNetworkWithChain = async (externalProvider?: ExternalProvider, networkID?: number) => {
  const provider = externalProvider || window.ethereum
  const chainId = (networkID || parseInt(getChainID())) as keyof typeof NETWORK_CONFIG

  if (!NETWORK_CONFIG[chainId]) {
    console.error('Invalid chain id')
    return false
  }

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })

      if (isWindow()) window.localStorage.setItem(CHAIN_ID_KEY, chainId.toString())
      return true
    } catch (switchError: any) {

      if (switchError?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: NETWORK_CONFIG[chainId].nativeCurrency,
                rpcUrls: NETWORK_URLS[chainId],
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          })
          if (isWindow()) window.localStorage.setItem(CHAIN_ID_KEY, chainId.toString())
          return true
        } catch (error: any) {
          if(error?.code === -32602) {
            try {
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: `0x${chainId.toString(16)}`,
                    chainName: NETWORK_CONFIG[chainId].name,
                    nativeCurrency: NETWORK_CONFIG[chainId].nativeCurrency,
                    rpcUrls: [NETWORK_URLS[chainId]],
                    blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
                  },
                ],
              })
              if (isWindow()) window.localStorage.setItem(CHAIN_ID_KEY, chainId.toString())
              return true
            } catch (error: any) {
              console.error('Failed to setup the network in Metamask:', error)
              return false
            }
          }

        }
      }
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

export type LibraryType = ExternalProvider & {
  isBitKeep: boolean
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenLogo?: string,
  library?: Web3Provider
) => {
  // better leave this undefined for default image instead of broken image url
  const image = tokenLogo ? (BAD_SRCS[tokenLogo] ? undefined : tokenLogo) : undefined
  const provider = (library?.provider || window.ethereum) as LibraryType;
  if (provider.isBitKeep) {
    const tokenAdded = await provider.request({
      method: 'wallet_watchAsset',
      params: [{
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image,
        },
      }],
    })

    return tokenAdded
  }

  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image,
      },
    },
  })

  return tokenAdded
}
