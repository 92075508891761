import { CHAIN_ID_KEY } from "@/config";
import isWindow from "./isWindow";

export const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID

export default function getChainID() {
    const CHAIN_ID = DEFAULT_CHAIN_ID;
    let chainId = CHAIN_ID;
    
    if(isWindow()){
        chainId = JSON.parse(window.localStorage.getItem(CHAIN_ID_KEY)) ?? CHAIN_ID
    }

    return chainId
}