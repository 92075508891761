import { ReactElement } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import styles from './BackButton.module.scss'

const BackButton = (): ReactElement => {
  const router = useRouter()
  return (
    <button onClick={() => router.back()} className={styles.back_button}>
      <Image src="/svgs/arrow-left.svg" layout="fixed" height="12rem" width="12rem" alt="NFT" />
      <span className="text-xl"> Go Back </span>
    </button>
  )
}

export default BackButton
