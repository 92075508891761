import { CHAIN_ID } from "@/config/constants/networks"
import { ChainId } from "@pancakeswap/sdk"

const INPUT_TOKENS = {
    [ChainId.MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [ChainId.TESTNET]: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    [ChainId.MATIC]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    [ChainId.MUMBAI]: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    [ChainId.CUBE]: '0x9D3F61338d6Eb394e378D28C1Fd17d5909aC6591',
    [ChainId.CUBETESTNET]: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    [ChainId.ETHEREUM]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
}

const OUTPUT_TOKENS = {
    [ChainId.MAINNET]: '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a', //DES
    [ChainId.TESTNET]: '0x3d8f2Ada8e97e4eF19e4ccBf6ec1Ca52900406aA',
    [ChainId.MATIC]: '0xa062fc09cA6bdeb2f6E3b77E1d4e09C42C964742', // DES
    [ChainId.MUMBAI]: '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
    [ChainId.CUBE]: '0x79F1520268A20c879EF44d169A4E3812D223C6de',
    [ChainId.CUBETESTNET]: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    [ChainId.ETHEREUM]: '0x634239cfA331Df0291653139d1a6083B9cf705e3', // DES
}
export const DES_TOKEN = '0xD07eBae754b7EA7120C1dE9Ce43262AA5A6406D0' // '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a';

// BNB
export const DEFAULT_INPUT_CURRENCY = '' //INPUT_TOKENS[CHAIN_ID]
export const DEFAULT_OUTPUT_CURRENCY = OUTPUT_TOKENS[CHAIN_ID] // "0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce" //"0x79F1520268A20c879EF44d169A4E3812D223C6de"
