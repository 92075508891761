import Image from 'next/image'
import styles from './RadioGroup.module.scss'

interface Options {
  text: string
  value: string
  icon: JSX.Element
}

interface Props {
  icon?: string
  value?: string | number
  name?: string
  label?: string
  className?: string
  required?: boolean
  description?: string
  options: Options[]
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
}

const RadiGroup = ({
  name,
  icon,
  label,
  onChange,
  onBlur,
  onFocus,
  value,
  className,
  required,
  description,
  options,
}: Props) => {
  const toogleOptions = (e: React.FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }

  return (
    <div className={`input ${className}`}>
      {!!label && (
        <label className="input_label" htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
      )}

      {!!description && <p className="input_description">{description}</p>}
      <div className={styles.radio}>
        {!!icon && (
          <figure className="input_icon">
            <Image src={icon} layout="fill" alt="icon" />
          </figure>
        )}
        <div className={styles.radio_group}>
          {options.map((option, i) => {
            return (
              <div key={i} className={styles.radio_item}>
                <input
                  type="radio"
                  id={option.value}
                  name={name}
                  value={option.value}
                  className={styles.radio_input}
                  checked={option.value === value}
                  onChange={(e) => toogleOptions(e)}
                />
                <label htmlFor={option.value} className={styles.radio_label}>
                  <div className={styles.radio_icon}>{option.icon}</div>

                  <span>{option.text}</span>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RadiGroup
