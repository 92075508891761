import React, { useState, useEffect, useContext, createContext } from 'react'
import { Tokens, tokens } from '@/mock/tokens'

interface Active {
  slippageActive: number
  gasActive: number
}
export interface Props {
  tokenList: Tokens[] | null
  setTokenList: (tokenList: Tokens[]) => void
  token1: Tokens | null
  token2: Tokens | null
  setToken1: (tokenList: Tokens) => void
  setToken2: (tokenList: Tokens) => void
  swapOrder: string
  setSwapOrder: (order: string) => void
  isSwap: boolean
  setIsSwap: (isSwap: boolean) => void
  chartTime: string
  setChartTime: (chartTime: string) => void
  slippageValue: number | string
  isCustomSlippage: boolean
  gasValue: number | string
  isSettingsActive: Active
  setSlippageValue: (slippageValue: number | string) => void
  setIsCustomSlippage: (isCustomSlippage: boolean) => void
  setGasValue: (gasValue: number | string) => void
  setIsSettingsActive: (isSettingsActive: Active) => void
  isStaking: boolean
  setIsStaking: (isStaking: boolean) => void
  collapseMobHeader: boolean
  setCollapseMobHeader: (collapseMobheader: boolean) => void
  chain: string
  setChain: (chain: string) => void
  collapseSidebar: boolean
  setCollapseSidebar: (collapseSidebar: boolean) => void
}
const AppContext = createContext<Props | null>(null)

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [collapseMobHeader, setCollapseMobHeader] = useState<boolean>(true)
  const [collapseSidebar, setCollapseSidebar] = useState<boolean>(true)
  const [tokenList, setTokenList] = useState<Tokens[]>(tokens)
  const [token1, setToken1] = useState<Tokens>(tokenList[0])
  const [token2, setToken2] = useState<Tokens>(tokenList[1])
  const [swapOrder, setSwapOrder] = useState<string>('market')
  const [isSwap, setIsSwap] = useState<boolean>(true)
  const [chartTime, setChartTime] = useState<string>('24 hours')
  const [slippageValue, setSlippageValue] = useState<number | string>(5)
  const [isCustomSlippage, setIsCustomSlippage] = useState<boolean>(false)
  const [gasValue, setGasValue] = useState<number | string>('Standard (65 Gwei)')
  const [isStaking, setIsStaking] = useState<boolean>(false)
  const [chain, setChain] = useState<string>('polygon')
  const [isSettingsActive, setIsSettingsActive] = useState<Active>({
    slippageActive: 0,
    gasActive: 0,
  })

  return (
    <AppContext.Provider
      value={{
        tokenList,
        setTokenList,
        token1,
        setToken1,
        token2,
        setToken2,
        swapOrder,
        setSwapOrder,
        isSwap,
        setIsSwap,
        chartTime,
        setChartTime,
        slippageValue,
        setSlippageValue,
        isCustomSlippage,
        setIsCustomSlippage,
        gasValue,
        setGasValue,
        isSettingsActive,
        setIsSettingsActive,
        isStaking,
        setIsStaking,
        collapseMobHeader,
        setCollapseMobHeader,
        chain,
        setChain,
        collapseSidebar,
        setCollapseSidebar,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
