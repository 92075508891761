import { ChainId } from '@pancakeswap/sdk'
import { CHAIN_ID } from './networks'

export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.NEXT_PUBLIC_GRAPH_API_PREDICTION
export const GRAPH_API_LOTTERY = process.env.NEXT_PUBLIC_GRAPH_API_LOTTERY
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
export const API_NFT = process.env.NEXT_PUBLIC_API_NFT
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

export const GAS_PRICE_API = 'https://api.despace.app/average-gas-price'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const OVERVIEW_BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const OVERVIEW_INFO_CLIENT = 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'

const EXCHANGE_SUBGRAPH_URL = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/despacedefi/despace-dex-bsc',
  [ChainId.TESTNET]: 'https://api.thegraph.com/subgraphs/name/despacedefi/despace-dex-bsc',
  [ChainId.MATIC]: 'https://api.thegraph.com/subgraphs/name/despacedefi/despace-dex-matic',
  [ChainId.MUMBAI]: 'https://api.thegraph.com/subgraphs/name/despacedefi/despace-dex-matic',
  [ChainId.CUBE]: 'https://subgraphs.despace.app/subgraphs/name/akhils10/deswap-cubechain',
  [ChainId.CUBETESTNET]: 'https://subgraphs.despace.app/subgraphs/name/akhils10/deswap-cubechain',
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/despacedefi/despace-dex-mainnet',
}

const BLOCKS_SUBGRAPH_URL = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/ducquangkstn/ethereum-blocks-bsc',
  [ChainId.TESTNET]: 'https://api.thegraph.com/subgraphs/name/ducquangkstn/ethereum-blocks-bsctestnet',
  [ChainId.MATIC]: 'https://api.thegraph.com/subgraphs/name/ord786/matic-blocks',
  [ChainId.MUMBAI]: 'https://api.thegraph.com/subgraphs/name/piavgh/mumbai-blocks',
  [ChainId.CUBE]: 'https://info.capricorn.finance/subgraphs/name/blocklytics/ethereum-blocks',
  [ChainId.CUBETESTNET]: 'https://info.capricorn.finance/subgraphs/name/blocklytics/ethereum-blocks',
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
}

export const INFO_CLIENT = EXCHANGE_SUBGRAPH_URL[CHAIN_ID] //'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'
export const BLOCKS_CLIENT = BLOCKS_SUBGRAPH_URL[CHAIN_ID] //'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'

export const GRAPH_API_NFTMARKET = process.env.NEXT_PUBLIC_GRAPH_API_NFT_MARKET
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'
