export const getSingleCollection = async (id: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/${id}`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const getCollectionByShortUrl = async (shortUrl: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/${shortUrl}/short_url`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const getSingleNft = async (address: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/${address}/nft-address`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const createCollection = async (id: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/${id}`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const updateCollection = async (id: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/${id}`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const getNfts = async (page = 1, limit = 15, chainId) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft?page=${page}&limit=${limit}&chainId=${chainId}`
  try {
    const response = await fetch(url)
    const format = await response.json()
    return {
      ...format,
      data: format.data.map((nft) => {
        return {
          ...nft,
          uri: nft.uri.includes('https') ? nft.uri : 'https://ipfs.io/ipfs/' + nft.uri,
        }
      }),
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUserNfts = async (address: string) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/${address}/owner-address`
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export const apiCall = async (
  url: string | any,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE',
  body?: any,
  stringify = true,
) => {
  const response = await fetch(url, {
    method,
    body: stringify ? JSON.stringify(body) : body,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  return await response.json()
}
export const createNft = async (account: string) => {
  const payload = {
    contractAddress: '0xdd9b2f085cbb52b25f57899cabd668ef69453d3e',
    ownerAddress: account,
    txHash: '0x5112e15fa0f4deda30d3ef3a356d236ab5ff328447f6ec59a502cf018b9f9a68',
    collectionId: '',
    name: 'Sakura',
    symbol: 'AAA',
    uri: 'https://cdn.pixabay.com/photo/2021/08/01/19/00/cloud-6515064__340.jpg',
    royalty: 1,
  }
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft`
  try {
    const response = await apiCall(url, 'POST', payload)
    const format = await response.json()
    return format
  } catch (error) {
    console.log(error)
  }
}

export const uploadFile = async (file) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/ipfs/add-file`
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: file,
    })
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}
