import { ChainId } from "@pancakeswap/sdk";
import { useGasPrice, useGasPriceManager } from "@/state/user/hooks";
import { parseUnits } from "@ethersproject/units";
import { useEffect } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import { GAS_PRICE_API } from "@/config/constants/endpoints";
import { CHAIN_ID } from "@/config/constants/networks";

export default function () {
    const { library, chainId } = useActiveWeb3React()
    const [, setGasPrice] = useGasPriceManager()
    const gasPrice = useGasPrice();

    useEffect(() => {
        const fallback = () => {
            library
                ?.getGasPrice()
                .then(res => {
                    console.log('[gas_price] full node: ', res.toString() + ' wei')
                    setGasPrice(res.toString());
                })
                .catch(e => {
                    setGasPrice(undefined)
                    console.error(e)
                })
        }

        const fetchGas = (chain: string) => {
            if (chain || ChainId.CUBE === +CHAIN_ID) {
                fallback()
                return
            }

            fetch(GAS_PRICE_API + `/${chain}`)
                .then(res => res.json())
                .then(json => {
                    if (!!json && !json.error && !!json.gasPrice) {
                        console.log('[gas_price] api: ', json.gasPrice.fast + ' gwei')
                        setGasPrice(parseUnits(json.gasPrice.fast, 'gwei').toString())
                    } else {
                        fallback()
                    }
                })
                .catch(e => {
                    fallback()
                })
        }

        let interval: any = null
        const chain =
            chainId === ChainId.MAINNET
                ? 'bsc'
                : chainId === ChainId.TESTNET
                    ? 'bsc'
                    : chainId === ChainId.MATIC
                        ? 'polygon'
                        : chainId === ChainId.ETHEREUM
                            ? 'ethereum'
                            : ''

        if (!!chainId) {
            fetchGas(chain)
            interval = setInterval(() => fetchGas(chain), 10000)
        } else {
            setGasPrice(gasPrice);
        }
        return () => {
            clearInterval(interval)
        }
    })
}