import { useState, useEffect } from 'react'
import Image from 'next/image'
import styles from './NftFormSelect.module.scss'

interface Props {
  icon?: string
  placeholder?: string
  value?: string | number
  name?: string
  label?: string
  className?: string
  required?: boolean
  description?: string
  prepend?: string
  items: any
  hint?: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
}

interface SelectItem {
  text: string
  value: string | number
  icon?: string
  active: boolean
  disabled?: boolean
}

const SelectField = ({
  name,
  icon,
  label,
  placeholder,
  onChange,
  value,
  className,
  required,
  description,
  prepend,
  items,
  hint,
  onBlur,
}: Props) => {
  // State Variables
  const [menuOpen, setMenuOpen] = useState<Boolean>(false)
  const [menuItems, setMenuItems] = useState<SelectItem[]>([])
  const [selected, setSelected] = useState<string | number>('')

  const changeActive = (i: number) => {
    if (!menuItems[i].disabled) {
      setSelected(menuItems[i].text)
      onChange({ target: { name: name, value: menuItems[i].value } })
      if (typeof onBlur === 'function') {
        onBlur({ target: { name: name, value: menuItems[i].value } })
      }

      setMenuOpen(false)
    }
  }

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(!menuOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const getSelectedIcon = (): string => {
    return menuItems.find((item: SelectItem) => item.text === selected)?.icon || ''
  }

  useEffect(() => {
    setMenuItems(
      items.map((item: any) => {
        return { ...item }
      }),
    )
    // get inital selected value label
    if (value) {
      setSelected(menuItems.find((item: any) => item.value === value)?.text || '')
    }
  }, [items, value])

  useEffect(() => {
    const handleClickOutside = () => {
      setMenuOpen(false)
    }

    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={`input ${className} ${styles.select} ${description && styles.select_desc}`}>
      {!!label && (
        <label className="input_label" htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
      )}

      {!!description && <p className="input_description">{description}</p>}
      <button onClick={toggleMenu} className={`input_wrapper ${styles.select_wrapper}`} aria-haspopup="listbox">
        {!!icon ||
          (getSelectedIcon() && (
            <figure className="input_icon">
              <Image src={icon || getSelectedIcon()} layout="fill" alt="icon" />
            </figure>
          ))}
        {!!prepend && <p className="input_prepend">{prepend}</p>}
        <div className={`input_field ${styles.select_input}`}>{selected || placeholder}</div>
        <div className={styles.select_dropdown}>
          <Image src="/svgs/chevron-bottom.svg" layout="fill" alt="icon" />
        </div>
      </button>
      {!!hint && <p className="input_hint">{hint}</p>}
      {menuOpen && (
        <div className={styles.select_menu}>
          {menuItems.map((item: SelectItem, i: number) => {
            return (
              <div
                key={i}
                className={`${styles.select_menu_item}
                  ${item.text === selected && styles.select_menu_item_active} ${
                  item.disabled ? styles.select_menu_item_disabled : ''
                }`}
                onClick={() => changeActive(i)}
              >
                {item.icon && <Image src={item.icon} layout="fixed" height="20rem" width="20rem" alt={item.text} />}
                <span>{item.text}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SelectField
