import { Button } from "@/shared";
import React, {useState, useEffect} from "react";
import styles from "./SwapSelect.module.scss";
import Image from "next/image";
import { useGlobalContext } from "@/contexts/AppContext";
import { useRouter } from "next/router";

export interface OptionProps {
    label: string;
  }

  export interface SwapSelectProps {
    options: string[];
    setLimit?: () => void;
    setMarket?: () => void;
    defaultOptionIndex?: number;
    className?: string;
  }

const SwapSelect: React.FunctionComponent<SwapSelectProps>  = ({
    options,
    defaultOptionIndex = 0,
    className
  }) => {
    const {setSwapOrder}:any = useGlobalContext()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(defaultOptionIndex)
    const router = useRouter();
    const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsOpen(!isOpen)
        event.stopPropagation()
      }
    
      const onOptionClicked = (selectedIndex: number) => () => {
        setSelectedOptionIndex(selectedIndex)
        setSwapOrder(options[selectedIndex])
        setIsOpen(false)

        if(options[selectedIndex] === "Market"){
           router.replace('/swap')
        }
        if(options[selectedIndex] === "Limit"){
            router.replace('/limit-orders') 
         }
      }

      useEffect(() => {
        const handleClickOutside = () => {
          setIsOpen(false)
        }
    
        document.addEventListener('click', handleClickOutside)
        return () => {
          document.removeEventListener('click', handleClickOutside)
        }
      }, [])
  return (
    <div className={`${styles.swapSelect} ${className}`}>
        <Button
        className={styles.swapSelect_header}
        type="transparent"
        onClick={toggling}>
            <div className={styles.swapSelect_smallRow}>                    
                <p>
                    {options[selectedOptionIndex]} Order
                </p>
                <div className={styles.swapSelect_icon}>                    
                    <Image src="/svgs/select-drop-down.svg" layout="fill"  alt="" />
                </div>
            </div>
        </Button> 
        {isOpen && <div className={styles.swapSelect_body}>                    
            <ul className={styles.swapSelect_listContainer}>
                {options.map((option, index) =>
                    index !== selectedOptionIndex ? (
                    <li onClick={onOptionClicked(index)} key={index} className={styles.swapSelect_listItem}>
                        <Button type="transparent" className={styles.swapSelect_row}>                    
                            <p>
                                {option} Order
                            </p>
                        </Button>
                    </li>
                    ) : null,
                )}
            </ul>
        </div>}
    </div>
  )
}

export default SwapSelect