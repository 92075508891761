import { useAggregatorContract, useTokenContract } from '@/hooks/useContract'
import { useState, useRef, useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { getTokenAddress } from '@/components/Swap/Chart2/utils'
import { AggregatorRouterEnum, AggregatorCurrency } from './types'
import { formatEther, formatUnits, parseEther, parseUnits } from '@ethersproject/units'
import { allExchanges } from './selectors'
import { setBestRoute } from './actions'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/state'
import {
  Currency as CurrencyCAKE,
  CurrencyAmount as CurrencyAmountCAKE,
  Token as TokenCAKE,
  JSBI as JSBICAKE,
  TokenAmount as TokenAmountCAKE,
  ETHER as ETHERCAKE,
  ChainId as ChainIdCAKE,
  WETH as WETHCAKE,
  Trade as TradeCAKE,
  Percent as PercentCAKE,
} from '@pancakesdk'
import {
  Currency as CurrencyDES,
  CurrencyAmount as CurrencyAmountDES,
  Token as TokenDES,
  JSBI as JSBIDES,
  TokenAmount as TokenAmountDES,
  ETHER as ETHERDES,
  ChainId as ChainIdDES,
  WETH as WETHDES,
  Trade as TradeDES,
  Percent as PercentDES,
} from '@pancakeswap/sdk'
import {
  Currency as CurrencyUNI,
  CurrencyAmount as CurrencyAmountUNI,
  Token as TokenUNI,
  JSBI as JSBIUNI,
  TokenAmount as TokenAmountUNI,
  ETHER as ETHERUNI,
  ChainId as ChainIdUNI,
  WETH as WETHUNI,
  Trade as TradeUNI,
  Percent as PercentUNI,
} from '@uniswap/sdk'
import {
  Currency as CurrencyQUICK,
  CurrencyAmount as CurrencyAmountQUICK,
  Token as TokenQUICK,
  JSBI as JSBIQUICK,
  TokenAmount as TokenAmountQUICK,
  ETHER as ETHERQUICK,
  ChainId as ChainIdQUICK,
  WETH as WETHQUICK,
  Trade as TradeQUICK,
  Percent as PercentQUICK,
} from 'quickswap-sdk'
import { useSingleContractMultipleData, useMultipleContractSingleData, useSingleCallResult } from '../multicall/hooks'
import ERC20_INTERFACE from 'config/abi/erc20'
import { calculateGasMargin, isAddress } from 'utils'
import { useMulticallContract } from 'hooks/useContract'
import orderBy from 'lodash/orderBy'
import { useGasPrice } from '../user/hooks'
import { getAddress } from '@/utils/addressHelpers'
import addresses from 'config/constants/contracts'
import { Contract } from 'ethers'
import { ROUTER_ADDRESS } from '@/config/constants'

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // v2 router 02
]

export const routerToName = {
  [AggregatorRouterEnum.UNISWAP_V2]: { name: 'Uniswap V2', img: '/svgs/logo-uniswap.svg' },
  [AggregatorRouterEnum.UNISWAP_V3]: { name: 'Uniswap V3', img: '' },
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_02]: {
    name: 'PANCAKESWAP_2',
    img: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7186.png',
  },
  [AggregatorRouterEnum.SUSHISWAP]: { name: 'Sushiswap', img: '' },
  [AggregatorRouterEnum.DESPACE_TESTNET]: { name: 'Despace', img: '/svgs/icon-des.svg' },
  [AggregatorRouterEnum.DESWAP_TEST]: { name: 'Despace Router(Test)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_CUBE]: { name: 'Despace(CUBE)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_MUMBAI]: { name: 'Despace(MUMBAI)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_MATIC]: { name: 'Despace(MATIC)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_CUBETESTNET]: { name: 'Despace(CUBETESTNET)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_ETHEREUM]: { name: 'Despace(ETHEREUM)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.DESWAP_MAINNET]: { name: 'Despace(MAINNET)', img: '/svgs/icon-swap.svg' },
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_01]: {
    name: 'PANCAKESWAP_1',
    img: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7186.png',
  },
  [AggregatorRouterEnum.PANCAKESWAP_BSC]: {
    name: 'PANCAKESWAP',
    img: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7186.png',
  },
  [AggregatorRouterEnum.PANCAKESWAP_ETH]: {
    name: 'PANCAKESWAP',
    img: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7186.png',
  },
  [AggregatorRouterEnum.QUICKSWAP]: {
    name: 'QuickSwap',
    img: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7186.png',
  },
} as Record<AggregatorRouterEnum, { name: string; img: string }>

export function useAggregator() {
  const aggregatorContract = useAggregatorContract()
  const gasPrice = useGasPrice()
  const { library, account } = useActiveWeb3React()

  const checkApproval = async (contractRes) => {
    const approvedForSpend = await contractRes.allowance(account, getAddress(addresses.aggregator))
    return approvedForSpend
  }

  /**
   * returns the fee rate in the smart contract. 1000 = 1%.
   */
  const getFee = async () => {
    try {
      const res = await aggregatorContract.fee()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
    * 
    * @param routerAddresses address array of the router(s) to query,
    * @param tokenAddresses  address array of the tokens to query for,
    * @param amount amount to swap with (amount in) in ETHERS
    * @param typedValue amount to swap with (amount in)
    * @param currency 
    * @returns {
        uint256[]: array of amounts from the routers in the correct index as input,
        uint256[]: array of amounts in opposite TokenDES | TokenCAKE direction from the routers in the correct index as input
      }
    */
  const getReturn = async (routerAddresses, tokenAddresses, amount, typedValue, currency) => {
    try {
      const res = await aggregatorContract.getReturn(routerAddresses, tokenAddresses, amount)
      return {
        router: routerAddresses[0],
        name: routerToName[routerAddresses[0]].name,
        amount1: await BigNumber.from(res[0][0]).toString(),
        transactionAmount: formatUnits(await BigNumber.from(res[1][0]).toString(), currency.decimals),
        price: (
          parseFloat(formatUnits(await BigNumber.from(res[1][0]).toString(), currency.decimals)) /
          parseFloat(typedValue)
        ).toFixed(6),
      }
    } catch (error) {
      return undefined
    }
  }

  /**
    * 
    * @param routerAddresses address array of the router(s) to query,
    * @param tokenAddresses  address array of the tokens to query for,
    * @param amountOut amount to swap with (amount out) in ETHERS
    * @param typedValue amount to swap with (amount in)
    * @param currency 
    * @returns {
        uint256[]: array of amounts from the routers in the correct index as input,
        uint256[]: array of amounts in opposite TokenDES | TokenCAKE direction from the routers in the correct index as input
      }
    */
  const getInput = async (routerAddresses, tokenAddresses, amountOut, typedValue, currency) => {
    try {
      const res = await aggregatorContract.getInput(routerAddresses, tokenAddresses, amountOut, currency)
      return {
        router: routerAddresses[0],
        name: routerToName[routerAddresses[0]].name,
        amount1: await BigNumber.from(res[0][0]).toString(),
        transactionAmount: formatUnits(await BigNumber.from(res[1][0]).toString(), currency.decimals),
        price: (
          parseFloat(formatUnits(BigNumber.from(res[1][0]).toString(), currency.decimals)) /
          parseFloat(formatUnits(BigNumber.from(res[0][0]).toString(), currency.decimals))
        ).toFixed(5),
      }
    } catch (error) {
      return undefined
    }
  }

  /**
   *
   * @param router address of the router (uniswap V2 forks),
   * @param address address of the token,
   * @param amount { value: amount of ether to swap }
   * @param gasEstimate: gas estimate from the smart contract
   */
  const swapETHForTokens = async (router: string, tokenAddress: string, amount: any, gasEstimate: BigNumber) => {
    const gasLimitEstimate = BigNumber.from('388024')

    const gasLimit = calculateGasMargin(gasLimitEstimate)

    try {
      const res = await aggregatorContract.swapETHForTokens(router, tokenAddress, {
        value: amount,
        gasLimit,
        gasPrice,
      })

      return res
    } catch (error) {
      console.log(error, 'ERROR FROM TRADE')
    }
  }

  /**
   *
   * @param router address of the router (uniswap V2 forks),
   * @param address address of the token,
   * @param amount amount of tokens to swap
   * @param gasEstimate: gas estimate from the smart contract
   */
  const swapTokensForETH = async (router: string, address: string, amount: any, gasEstimate: BigNumber) => {
    const gasLimitEstimate = BigNumber.from('388024')
    const gasLimit = calculateGasMargin(gasLimitEstimate)

    // const contractRes = new Contract(address, ERC20_INTERFACE, library.getSigner())
    // const approvalRes = await checkApproval(contractRes)

    // if (BigNumber.from(approvalRes).lt(BigNumber.from(amount))) {
    //   // approve the aggregator
    //   const approval = await contractRes.approve(
    //     getAddress(addresses.aggregator),
    //     parseEther(BigNumber.from(await contractRes.totalSupply()).toString()),
    //   )
    //   await approval.wait(1)
    // }
    try {
      const res = await aggregatorContract.swapTokensForETH(router, address, amount, {
        gasLimit,
        gasPrice,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @param router address of the router (uniswap V2 forks),
   * @param tokenAddresses address array of the tokens to swap between. The 0 index should be the input token, while the 1 index should be the output,
   * @param amount amount of tokens to swap
   *  @param gasEstimate: gas estimate from the smart contract
   */
  const swapTokensForTokens = async (router: string, tokenAddresses: string[], amount: any, gasEstimate: BigNumber) => {
    const gasLimitEstimate = BigNumber.from('388024')
    const gasLimit = calculateGasMargin(gasLimitEstimate)
    const contractRes = new Contract(tokenAddresses[0], ERC20_INTERFACE, library.getSigner())
    const approvalRes = await checkApproval(contractRes)

    // if (BigNumber.from(approvalRes).lt(BigNumber.from(amount))) {
    //   // approve the aggregator
    //   const approval = await contractRes.approve(
    //     getAddress(addresses.aggregator),
    //     parseEther(BigNumber.from(await contractRes.totalSupply()).toString()),
    //   )
    //   await approval.wait(1)
    // }

    try {
      const res = await aggregatorContract.swapTokensForTokens(router, tokenAddresses, amount, {
        gasLimit,
        gasPrice,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const afterFee = async (amount) => {
    try {
      const res = await aggregatorContract.afterFee()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getFee,
    getReturn,
    getInput,
    afterFee,
    swapETHForTokens,
    swapTokensForETH,
    swapTokensForTokens,
  }
}

export function useDexRouter(): {
  routerTradeDetails: any
  getRouterFees: (
    inputCurrencyId: any,
    outputCurrencyId: any,
    inputAmount: any,
    typedValue: any,
    currency: AggregatorCurrency | undefined,
  ) => Promise<any>
  loading: any
  newValue: any
  resetAggregatorState: () => void
} {
  const routerTradeDetails = useRef([])
  const loading = useRef(false)
  const prevValue = useRef('0')
  const newValue = useRef<string>()
  const { getReturn, getFee, getInput } = useAggregator()
  const { chainId } = useActiveWeb3React()
  const { routers, bestRoute, reverseCheck } = allExchanges()
  const dispatch = useDispatch<AppDispatch>()

  const getRouterFees = async (inputCurrencyId, outputCurrencyId, inputAmount, typedValue, currency) => {
    try {
      if (prevValue.current !== typedValue) {
        loading.current = true
        prevValue.current = typedValue
        routerTradeDetails.current = []
      }
      const routerArray = routers[chainId].filter((router) => router.isChecked).map((router) => router.routerAddress)
      const response = (await Promise.all(
        routerArray.map(async (address) => {
          if (reverseCheck) {
            return await getInput(
              [address],
              [getTokenAddress(inputCurrencyId), getTokenAddress(outputCurrencyId)],
              inputAmount,
              typedValue,
              currency,
            )
          } else {
            return await getReturn(
              [address],
              [getTokenAddress(inputCurrencyId), getTokenAddress(outputCurrencyId)],
              inputAmount,
              typedValue,
              currency,
            )
          }
        }),
      )) as Array<any>
      routerTradeDetails.current = response
        .filter((item) => item)
        .sort((a, b) => {
          return b.transactionAmount - a.transactionAmount
        })

      newValue.current = !reverseCheck
        ? routerTradeDetails.current[0]?.amount1 || parseEther(typedValue)
        : parseEther(typedValue)

      dispatch(
        /**
         * Defaulting the router to that of pancake swap
         */
        setBestRoute({
          routerAddress: routerTradeDetails.current[0]?.router || ROUTER_ADDRESS[chainId],
        }),
      )

      return response
    } catch (error) {
      console.log(error)
    } finally {
      loading.current = false
    }
  }

  const resetAggregatorState = () => {
    routerTradeDetails.current = []
    newValue.current = '0'
    setBestRoute(ROUTER_ADDRESS[chainId])
  }

  return { routerTradeDetails, getRouterFees, loading, newValue, resetAggregatorState }
}

/**
 * Returns a map of the given addresses to their eventually consistent BNB balances.
 */
export function useBNBBalancesCAKE(
  bestRoute: string,
  uncheckedAddresses?: (string | undefined)[],
): {
  [address: string]: CurrencyAmountDES | CurrencyAmountCAKE | CurrencyAmountQUICK | CurrencyAmountUNI | undefined
} {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses ? orderBy(uncheckedAddresses.map(isAddress).filter((a): a is string => a !== false)) : [],
    [uncheckedAddresses],
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address]),
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmountDES | CurrencyAmountCAKE }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        switch (bestRoute) {
          case AggregatorRouterEnum.DESPACE_TESTNET:
          case AggregatorRouterEnum.DESWAP_TEST:
          case AggregatorRouterEnum.DESWAP_CUBE:
          case AggregatorRouterEnum.DESWAP_MUMBAI:
          case AggregatorRouterEnum.DESWAP_MATIC:
          case AggregatorRouterEnum.DESWAP_CUBETESTNET:
          case AggregatorRouterEnum.DESWAP_ETHEREUM:
          case AggregatorRouterEnum.DESWAP_MUMBAI:
            if (value) memo[address] = CurrencyAmountDES.ether(JSBIDES.BigInt(value.toString()))
            break
          case AggregatorRouterEnum.PANCAKESWAP_TESTNET_01:
          case AggregatorRouterEnum.PANCAKESWAP_TESTNET_02:
          case AggregatorRouterEnum.PANCAKESWAP_BSC:
          case AggregatorRouterEnum.PANCAKESWAP_ETH:
            if (value) memo[address] = CurrencyAmountCAKE.ether(JSBICAKE.BigInt(value.toString()))
            break
          case AggregatorRouterEnum.QUICKSWAP:
            if (value)
              memo[address] = CurrencyAmountQUICK.ether(
                JSBIQUICK.BigInt(value.toString()),
              ) as unknown as CurrencyAmountDES
            break
          case AggregatorRouterEnum.UNISWAP_V2:
            if (value)
              memo[address] = CurrencyAmountUNI.ether(JSBIUNI.BigInt(value.toString())) as unknown as CurrencyAmountDES
            break
          default:
            if (value) memo[address] = CurrencyAmountCAKE.ether(JSBICAKE.BigInt(value.toString()))
        }

        return memo
      }, {}),
    [addresses, results],
  )
}

/**
 * Returns a map of TokenDES | TokenCAKE addresses to their eventually consistent TokenDES | TokenCAKE balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicatorCAKE(
  bestRoute: string,
  address?: string,
  tokens?: (TokenDES | undefined)[],
): [{ [tokenAddress: string]: TokenAmountDES | undefined }, boolean] {
  const validatedTokens: TokenDES[] | TokenCAKE[] | TokenUNI[] | TokenQUICK[] = useMemo(() => {
    switch (bestRoute) {
      case AggregatorRouterEnum.DESPACE_TESTNET:
      case AggregatorRouterEnum.DESWAP_TEST:
      case AggregatorRouterEnum.DESWAP_CUBE:
      case AggregatorRouterEnum.DESWAP_MUMBAI:
      case AggregatorRouterEnum.DESWAP_MATIC:
      case AggregatorRouterEnum.DESWAP_CUBETESTNET:
      case AggregatorRouterEnum.DESWAP_ETHEREUM:
      case AggregatorRouterEnum.DESWAP_MUMBAI:
        return tokens?.filter((t?: TokenDES): t is TokenDES => isAddress(t?.address) !== false) ?? []

      case AggregatorRouterEnum.PANCAKESWAP_TESTNET_01:
      case AggregatorRouterEnum.PANCAKESWAP_TESTNET_02:
      case AggregatorRouterEnum.PANCAKESWAP_BSC:
      case AggregatorRouterEnum.PANCAKESWAP_ETH:
        const tokensCake = tokens as unknown as TokenCAKE[]
        return (
          tokensCake?.filter((t?: TokenCAKE): t is TokenCAKE => isAddress(t?.address) !== false) ??
          ([] as unknown as TokenDES[])
        )

      case AggregatorRouterEnum.UNISWAP_V2:
        const tokensUni = tokens as unknown as TokenUNI[]
        return (
          tokensUni?.filter((t?: TokenUNI): t is TokenUNI => isAddress(t?.address) !== false) ??
          ([] as unknown as TokenDES[])
        )

      case AggregatorRouterEnum.QUICKSWAP:
        const tokensQuick = tokens as unknown as TokenQUICK[]
        return (
          tokensQuick?.filter((t?: TokenQUICK): t is TokenQUICK => isAddress(t?.address) !== false) ??
          ([] as unknown as TokenDES[])
        )

      default:
        return (
          tokens?.filter((t?: TokenDES): t is TokenDES => isAddress(t?.address) !== false) ??
          ([] as unknown as TokenDES[])
        )
    }
  }, [tokens])

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20_INTERFACE,
    'balanceOf',
    useMemo(() => [address], [address]),
  )

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])
  const aggregatorValidatedTokens = validatedTokens as unknown as TokenDES[]

  return [
    useMemo(
      () =>
        address && aggregatorValidatedTokens.length > 0
          ? aggregatorValidatedTokens.reduce<{ [tokenAddress: string]: TokenAmountDES | undefined }>(
              (memo, Token, i) => {
                const value = balances?.[i]?.result?.[0]
                let amount
                switch (bestRoute) {
                  case AggregatorRouterEnum.DESPACE_TESTNET:
                  case AggregatorRouterEnum.DESWAP_TEST:
                  case AggregatorRouterEnum.DESWAP_CUBE:
                  case AggregatorRouterEnum.DESWAP_MUMBAI:
                  case AggregatorRouterEnum.DESWAP_MATIC:
                  case AggregatorRouterEnum.DESWAP_CUBETESTNET:
                  case AggregatorRouterEnum.DESWAP_ETHEREUM:
                  case AggregatorRouterEnum.DESWAP_MUMBAI:
                    amount = value ? JSBIDES.BigInt(value.toString()) : undefined
                    if (amount) {
                      memo[Token.address] = new TokenAmountDES(Token, amount)
                    }
                    return memo
                  case AggregatorRouterEnum.PANCAKESWAP_TESTNET_01:
                  case AggregatorRouterEnum.PANCAKESWAP_TESTNET_02:
                  case AggregatorRouterEnum.PANCAKESWAP_BSC:
                  case AggregatorRouterEnum.PANCAKESWAP_ETH:
                    amount = value ? JSBICAKE.BigInt(value.toString()) : undefined
                    if (amount) {
                      memo[Token.address] = new TokenAmountCAKE(Token as unknown as TokenCAKE, amount)
                    }
                    return memo
                  case AggregatorRouterEnum.UNISWAP_V2:
                    amount = value ? JSBIUNI.BigInt(value.toString()) : undefined
                    if (amount) {
                      memo[Token.address] = new TokenAmountUNI(
                        Token as unknown as TokenUNI,
                        amount,
                      ) as unknown as TokenAmountDES
                    }
                    return memo
                  case AggregatorRouterEnum.QUICKSWAP:
                    amount = value ? JSBIQUICK.BigInt(value.toString()) : undefined
                    if (amount) {
                      memo[Token.address] = new TokenAmountQUICK(
                        Token as unknown as TokenQUICK,
                        amount,
                      ) as unknown as TokenAmountDES
                    }
                    return memo
                  default:
                    amount = value ? JSBICAKE.BigInt(value.toString()) : undefined
                    if (amount) {
                      memo[Token.address] = new TokenAmountCAKE(Token as unknown as TokenCAKE, amount)
                    }
                    return memo
                }
              },
              {},
            )
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ]
}

export function useTokenBalancesCAKE(
  bestRoute: string,
  address?: string,
  tokens?: (TokenDES | TokenCAKE | TokenQUICK | TokenUNI | undefined)[],
): { [tokenAddress: string]: TokenAmountDES | TokenAmountCAKE | undefined } {
  return useTokenBalancesWithLoadingIndicatorCAKE(bestRoute, address, tokens as (TokenDES | undefined)[])[0]
}

// get the balance for a single Token/account combo
export function useTokenBalanceCAKE(
  bestRoute: string,
  account?: string,
  Token?: TokenDES | TokenCAKE,
): TokenAmountDES | TokenAmountCAKE | undefined {
  const tokenBalances = useTokenBalancesCAKE(bestRoute, account, [Token])
  if (!Token) return undefined
  return tokenBalances[Token.address]
}

export function useCurrencyBalancesCAKE(
  bestRoute: string,
  account?: string,
  currencies?: (CurrencyCAKE | undefined)[],
): (CurrencyAmountDES | CurrencyAmountCAKE | CurrencyAmountQUICK | CurrencyAmountUNI | undefined)[] {
  const tokens = useMemo(() => {
    switch (bestRoute) {
      case AggregatorRouterEnum.DESPACE_TESTNET:
      case AggregatorRouterEnum.DESWAP_TEST:
      case AggregatorRouterEnum.DESWAP_CUBE:
      case AggregatorRouterEnum.DESWAP_MUMBAI:
      case AggregatorRouterEnum.DESWAP_MATIC:
      case AggregatorRouterEnum.DESWAP_CUBETESTNET:
      case AggregatorRouterEnum.DESWAP_ETHEREUM:
      case AggregatorRouterEnum.DESWAP_MUMBAI:
        return currencies?.filter((currency): currency is TokenDES => currency instanceof TokenDES) ?? []

      case AggregatorRouterEnum.PANCAKESWAP_TESTNET_01:
      case AggregatorRouterEnum.PANCAKESWAP_TESTNET_02:
      case AggregatorRouterEnum.PANCAKESWAP_BSC:
      case AggregatorRouterEnum.PANCAKESWAP_ETH:
        return currencies?.filter((currency): currency is TokenCAKE => currency instanceof TokenCAKE) ?? []

      case AggregatorRouterEnum.UNISWAP_V2:
        return currencies?.filter((currency): currency is TokenUNI => currency instanceof TokenUNI) ?? []

      case AggregatorRouterEnum.QUICKSWAP:
        return currencies?.filter((currency): currency is TokenQUICK => currency instanceof TokenQUICK) ?? []

      default:
        return currencies?.filter((currency): currency is TokenCAKE => currency instanceof TokenCAKE) ?? []
    }
  }, [currencies])

  const tokenBalances = useTokenBalancesCAKE(bestRoute, account, tokens)
  const containsBNB: boolean = useMemo(
    () => currencies?.some((currency) => currency === ETHERDES) ?? false,
    [currencies],
  )
  const bnbBalance = useBNBBalancesCAKE(bestRoute, containsBNB ? [account] : [])

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency instanceof TokenDES) return tokenBalances[currency.address]
        if (currency instanceof TokenDES) return tokenBalances[currency.address]
        if (currency === ETHERDES) return bnbBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, bnbBalance, tokenBalances],
  )
}

export function useCurrencyBalanceCAKE(
  bestRoute: string,
  account?: string,
  currency?: CurrencyDES | CurrencyCAKE,
): CurrencyAmountDES | CurrencyAmountCAKE | CurrencyAmountQUICK | CurrencyAmountUNI | undefined {
  return useCurrencyBalancesCAKE(bestRoute, account, [currency])[0]
}

export function useTokenAllowanceCAKE(
  bestRoute: string,
  token?: TokenDES | TokenCAKE,
  owner?: string,
  spender?: string,
): TokenAmountDES | TokenAmountCAKE | TokenAmountQUICK | TokenAmountUNI | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(() => {
    if (token && allowance) {
      switch (bestRoute) {
        case AggregatorRouterEnum.DESPACE_TESTNET:
        case AggregatorRouterEnum.DESWAP_TEST:
        case AggregatorRouterEnum.DESWAP_CUBE:
        case AggregatorRouterEnum.DESWAP_MUMBAI:
        case AggregatorRouterEnum.DESWAP_MATIC:
        case AggregatorRouterEnum.DESWAP_CUBETESTNET:
        case AggregatorRouterEnum.DESWAP_ETHEREUM:
        case AggregatorRouterEnum.DESWAP_MUMBAI:
          return new TokenAmountDES(token, allowance.toString())

        case AggregatorRouterEnum.PANCAKESWAP_TESTNET_01:
        case AggregatorRouterEnum.PANCAKESWAP_TESTNET_02:
        case AggregatorRouterEnum.PANCAKESWAP_BSC:
        case AggregatorRouterEnum.PANCAKESWAP_ETH:
          return new TokenAmountCAKE(token as unknown as TokenCAKE, allowance.toString())

        case AggregatorRouterEnum.QUICKSWAP:
          return new TokenAmountQUICK(token as unknown as TokenQUICK, allowance.toString())

        case AggregatorRouterEnum.UNISWAP_V2:
          return new TokenAmountUNI(token as unknown as TokenUNI, allowance.toString())

        default:
          return new TokenAmountCAKE(token as unknown as TokenCAKE, allowance.toString())
      }
    } else {
      return undefined
    }
  }, [bestRoute, token, allowance])
}
