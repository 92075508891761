interface Logos {
  title: string
  src: string
  url?: string
}

interface Details {
  title: string
  value: number
}

export const logos: Logos[] = [
  {
    title: 'coingecko',
    src: '/svgs/logo-coingecko.svg',
    url: 'https://www.coingecko.com/en/coins/despace-protocol',
  },
  {
    title: 'coin-market-cap',
    src: '/svgs/logo-cmc.svg',
    url: 'https://coinmarketcap.com/currencies/despace-protocol/',
  },
  {
    title: 'bitmart',
    src: '/svgs/logo-b.svg',
    url: 'https://www.bitmart.com/trade/en?layout=basic&symbol=DES_USDT',
  },
  {
    title: 'gate',
    src: '/svgs/logo-g.svg',
    url: 'https://www.gate.io/ru/trade/DES_USDT',
  },
  {
    title: 'deswap',
    src: '/svgs/logo-mob.svg',
    url: 'https://despace.app/swap',
  },
  {
    title: '1inch',
    src: '/svgs/logo-uniswap.svg',
    url: 'https://app.1inch.io/#/56/swap/BUSD/DES',
  },
]

export const overviewDetails: Details[] = [
  {
    title: 'Your Total Staked Value',
    value: 45030,
  },
  {
    title: 'Your Estimated Rewards 24h',
    value: 234.12,
  },
  {
    title: 'Your Unclaimed Rewards',
    value: 121,
  },
]
