import styled from "styled-components";
import { m as Motion } from "framer-motion";

export const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 0.2rem;
    z-index: -1;
  }

  &::before {
    content: "";
    transform: rotate(45deg);
    background: ${({ theme }) => theme.tooltip.background};
  }
`;

export const StyledTooltip = styled(Motion.div)`
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 130%;
  border-radius: 1.6rem;
  max-width: 320px;
  z-index: 101;
  background: ${({ theme }) => theme.tooltip.background};
  color: ${({ theme }) => theme.tooltip.text};
  box-shadow: ${({ theme }) => theme.tooltip.boxShadow};

  &[data-popper-placement^="top"] > ${Arrow} {
    bottom: -0.4rem;
  }

  &[data-popper-placement^="bottom"] > ${Arrow} {
    top: -0.4rem;
  }

  &[data-popper-placement^="left"] > ${Arrow} {
    right: -0.4rem;
  }

  &[data-popper-placement^="right"] > ${Arrow} {
    left: -0.4rem;
  }
`;
