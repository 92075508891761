import { createAction } from '@reduxjs/toolkit'
import { AddCollectionPayload } from './types'
import { Nfts } from '../types'

export const updateStateNfts = createAction<{ data: Nfts[]; action?: 'update' | 'new' }>('nft/updateStateNfts')
export const toogleCollectionModal = createAction<{ data: AddCollectionPayload }>('nft/toogleCollectionModal')
export const fetchCollection = createAction<{ data: any }>('nft/fetchCollections')
export const addUserCollections = createAction<{ data: any }>('nft/addUserCollections')
export const saveNftPayload = createAction<{ data: any }>('nft/saveNftPayload')
export const updateMetaData = createAction<{
  data: {
    module: 'nftsMeta' | 'featuredNftsMeta' | 'collectionsMeta' | 'userCollectionsMeta'
    payload: { page: number; limit: number; count: number }
  }
}>('nft/updateMetaData')
export const updateNftRes = createAction<{ data: any }>('nft/updateNftRes')
export const updateNftFilters = createAction<{ data: any }>('nft/updateNftFilters')
