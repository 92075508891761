import { useGlobalContext } from '@/contexts/AppContext'
import { chains } from '@/mock'
import Search from '@/views/Info/components/InfoSearch'
import Image from 'next/image'
import Link from 'next/link'
import ConnectWallet from '../connectWallet/ConnectWallet'
import Logo from '../logo/Logo'
// import SearchBox from '../searchbox/SearchBox'
import { BridgeSelect } from '../selects'
import styles from './Header.module.scss'

const Header = () => {
  const { setCollapseMobHeader, collapseMobHeader, collapseSidebar }: any = useGlobalContext()

  return (
    <header className={styles.header} data-collapsed={collapseSidebar}>
      <div className={styles.header_logo}>
        <Link href={`/`}>
          <a>
            <Logo />
          </a>
        </Link>
      </div>
      <div className={styles.header_row}>
        <div className={styles.header_search}>
          <Search />
        </div>
        <div className={styles.small_row}>
          <BridgeSelect options={chains} />
          <ConnectWallet />
        </div>
        <div
          onClick={() => setCollapseMobHeader(!collapseMobHeader)}
          className={styles[collapseMobHeader ? 'header_hamburger' : 'header_hamburger__open']}
        >
          <span className={styles.header_hamburgerBar}></span>
          <span className={styles.header_hamburgerBar}></span>
        </div>
      </div>
    </header>
  )
}

export default Header
