import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
  MATIC = 137,
  MUMBAI = 80001,
  CUBE = 1818,
  CUBETESTNET = 1819,
  ETHEREUM = 1,
  // BSC = 56,
  // AVAX = 43114,
  // FANTOM = 250,
  // ARBITRUM = 42161,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS = '0xE4b72158459DE95E645A6c42FDe02a4d997073Bd'

export const FACTORY_ADDRESS_MAP = {
  [ChainId.MAINNET]: FACTORY_ADDRESS,
  [ChainId.TESTNET]: '0x11B4994DE5aC33dEF900b19Bae4Dd2a618b9b288',
  [ChainId.MATIC]: '0xA0EaD99F785F09CEb1607cEF27181CedCECaA4c6',
  [ChainId.MUMBAI]: '0x2DA235784Cb0283219709986916DD974c454E423',
  [ChainId.CUBE]: '0x28aaF78f6D62a9C9E2335287c96b615754382d96',
  [ChainId.CUBETESTNET]: '0xFFF9780E4DaD9844E0Ef1c2e7E226366229d510d',
  [ChainId.ETHEREUM]: '0x28aaF78f6D62a9C9E2335287c96b615754382d96',
}

export const INIT_CODE_HASH = '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9'

export const INIT_CODE_HASH_MAP = {
  [ChainId.MAINNET]: INIT_CODE_HASH,
  [ChainId.TESTNET]: '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9', //'0ae088a013ee4895ef65e71c1a4c49372a96d1fbeb015bfbee44cf3cff933ecc',
  [ChainId.MATIC]: '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9',
  [ChainId.MUMBAI]: '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9',
  [ChainId.CUBE]: '0x96757e5766f7e7c047193dc562206d112760b530c5b69d9966f86d33724c80cd',
  [ChainId.CUBETESTNET]: '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9',
  [ChainId.ETHEREUM]: '0x4cfdbd9412c313201c8e2f145161c99a6e59be6a7331cdc65348046ecc260bb9',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(997)
export const FEES_DENOMINATOR = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}
