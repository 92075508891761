import { useState } from 'react'
import styles from './NftCollection.module.scss'
import Image from 'next/image'
import { Nfts } from '@/state/types'
import { useNftState } from '@/state/nft/hooks'
import { Collection } from '@/state/nft/types'

const NftCollection = ({ hideDialog, nft }: { hideDialog: () => void; nft: Nfts }) => {
  const [currentCollection, setCurrentCollection] = useState<string>('SHA-Liama Collection-1')
  const { userCollections, showCollectionModal } = useNftState()

  return (
    <div className={styles.add_collection}>
      <div className={styles.add_collection_container}>
        <div className={styles.add_collection_heading}>
          <div>
            <h6>Add To A Collection</h6>
          </div>
          <button className={styles.add_collection_container_close} onClick={() => hideDialog()}>
            <Image layout="fill" src="/svgs/icon-close.svg" alt="close" />
          </button>
        </div>
        <div className={styles.add_collection_list}>
          {userCollections.map((collection: Collection) => {
            return (
              <div key={collection.uuid} onClick={() => setCurrentCollection(collection.uuid)}>
                <p>{collection.name}</p>
                {showCollectionModal.nft.collectionId === collection.uuid && (
                  <div className={styles.add_collection_check}>
                    <Image layout="fill" src="/svgs/icon-check.svg" alt="close" />
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <div className={styles.add_collection_details}>
          <button onClick={hideDialog} className={styles.add_collection_close_button}>
            Cancel
          </button>
          <button onClick={hideDialog} className={styles.add_collection_action_button}>
            Add To Collection
          </button>
        </div>
      </div>
    </div>
  )
}

export default NftCollection
