import { CHAIN_ID_KEY } from '@/config';
import { useAppDispatch } from '@/state';
import { updateLastConnectedChainId } from '@/state/user/actions';
import getChainID from '@/utils/getChainID';
import { useRouter } from 'next/router';
import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts';
import useActiveWeb3React from './useActiveWeb3React';


const useChainUpdater = () => {
  const { chainId } = useActiveWeb3React();
  const router = useRouter();
  const dispatch = useAppDispatch()
  const [chainID, setChainId] = useLocalStorage(CHAIN_ID_KEY, chainId.toString() ?? getChainID());

  useEffect(() => {
    if(chainId && (chainID !== chainId.toString())){
      setChainId(chainId.toString())
      dispatch(updateLastConnectedChainId(chainId))
      router.reload();
    }
  }, [chainId])
}

export default useChainUpdater
