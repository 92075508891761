import styles from './Title.module.scss';

interface Props {
	title?: string;
	description?: string;
}

const Title = ({ title = 'despace overview', description }: Props) => {
	return (
		<div className={styles.title}>
			<h1>{title}</h1>
			<p>{description}</p>
		</div>
	);
};

export default Title;
