import React from 'react'
import styles from "./PreLoader.module.scss"

const PreLoader = () => {
  return (
    <div className={styles.preloader}>
        <div className={styles.loader}></div>
    </div>
  )
}

export default PreLoader