import { ChainId, Currency } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const CHAIN_ID_KEY = 'CONNECTED_CHAIN_ID'

export const ETHERBYCHAIN = {
  [ChainId.MAINNET]: 'BNB',
  [ChainId.TESTNET]: 'BNB',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.MUMBAI]: 'MATIC',
  [ChainId.CUBE]: 'CUBE',
  [ChainId.CUBETESTNET]: 'CUBE',
  [ChainId.ETHEREUM]: 'ETH',
}

export const SUPPORTED_CHAIN_IDS: ChainId[] = [
  ChainId.MAINNET,
  ChainId.TESTNET,
  ChainId.MATIC,
  ChainId.CUBE,
  ChainId.ETHEREUM,
]

export const NETWORK_DETAILS: { [chainid in ChainId]?: { icon: string; label: string } } = {
  [ChainId.MAINNET]: {
    label: 'BNB Chain',
    icon: '/svgs/bsc.svg',
  },
  [ChainId.TESTNET]: {
    label: 'BNB Testnet',
    icon: '/svgs/bsc.svg',
  },
  [ChainId.MATIC]: {
    label: 'Polygon',
    icon: '/svgs/polygon.svg',
  },
  [ChainId.MUMBAI]: {
    label: 'Polygon Mumbai',
    icon: '/svgs/polygon.svg',
  },
  [ChainId.CUBE]: {
    label: 'Cube Chain',
    icon: '/svgs/cube.svg',
  },
  [ChainId.CUBETESTNET]: {
    label: 'Cube Testnet',
    icon: '/svgs/cube.svg',
  },
  [ChainId.ETHEREUM]: {
    label: 'Ethereum',
    icon: '/svgs/ethereum.svg',
  },
}

export const NETWORK_URLS: {
  [chainId in ChainId]?: string
} = {
  [ChainId.MAINNET]: 'https://bsc-dataseed.binance.org/',
  [ChainId.TESTNET]: `https://data-seed-prebsc-1-s1.binance.org:8545/`,
  [ChainId.MATIC]: `https://polygon-rpc.com/`,
  [ChainId.MUMBAI]: `https://rpc-mumbai.maticvigil.com`,
  [ChainId.CUBE]: `https://http-mainnet.cube.network`,
  [ChainId.CUBETESTNET]: ``,
  [ChainId.ETHEREUM]: `https://mainnet.infura.io/v3/756a7445788748908bd1ed65a029569a`,
  // [ChainId.AVAX]: `https://api.avax.network/ext/bc/C/rpc`,
  // [ChainId.FANTOM]: `https://rpc.ftm.tools`,
  // [ChainId.ARBITRUM]: `https://arb1.arbitrum.io/rpc`,
}

export const BASE_RPC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
  [ChainId.MATIC]: 'https://polygonscan.com',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com',
  [ChainId.CUBE]: 'https://cubescan.network',
  [ChainId.CUBETESTNET]: 'https://testnet.cubescan.network',
  [ChainId.ETHEREUM]: 'https://etherscan.io',
  // [ChainId.BSCTESTNET]: 'https://testnet.bscscan.com',
  // [ChainId.AVAX]: 'https://avascan.info',
  // [ChainId.FANTOM]: 'https://ftmscan.com',
  // [ChainId.ARBITRUM]: 'https://arbiscan.io',
}

export const RPC_SCAN_LABELS = {
  [ChainId.MAINNET]: 'BscScan',
  [ChainId.TESTNET]: 'BscScan',
  [ChainId.MATIC]: 'PolygonScan',
  [ChainId.MUMBAI]: 'PolygonScan',
  [ChainId.CUBE]: 'CubeScan',
  [ChainId.CUBETESTNET]: 'CubeScan',
  [ChainId.ETHEREUM]: 'EtherScan',
}

export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://despace.app'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_RPC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 20000000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000
