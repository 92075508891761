import { ChainId } from '@pancakeswap/sdk'
import { Currency as CurrencyCAKE, ETHER as ETHERCAKE, WETH as WETHCAKE, Trade as TradeCAKE } from '@pancakesdk'
import { Currency as CurrencyDES, ETHER as ETHERDES, WETH as WETHDES, Trade as TradeDES } from '@pancakeswap/sdk'
import { Currency as CurrencyUNI, ETHER as ETHERUNI, WETH as WETHUNI, Trade as TradeUNI } from '@uniswap/sdk'
import { Currency as CurrencyQUICK, ETHER as ETHERQUICK, WETH as WETHQUICK, Trade as TradeQUICK } from 'quickswap-sdk'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export type PairDataNormalized = {
  time: number
  token0Id: string
  token1Id: string
  reserve0: number
  reserve1: number
}[]

export type DerivedPairDataNormalized = {
  time: number
  token0Id: string
  token1Id: string
  token0DerivedBNB: number
  token1DerivedBNB: number
}[]

export type PairPricesNormalized = {
  time: Date
  value: number
}[]

export enum PairDataTimeWindowEnum {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export interface AggregatorState {
  routers: Record<ChainId, Array<any>>
  bestRoute: string
  reverseCheck: boolean
}

export enum AggregatorRouterEnum {
  UNISWAP_V2 = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  UNISWAP_V3 = '0xe592427a0aece92de3edee1f18e0157c05861564',
  PANCAKESWAP_TESTNET_02 = '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
  SUSHISWAP = '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f',
  DESPACE_TESTNET = '0x006B33A96Ac8281eF20818D0786cA2221240F278',
  DESWAP_TEST = '0xE9b04Bd34B07BB852F508d24b2D219c8ed2e9212',
  DESWAP_MAINNET = '0xE037d12345dA559C2Ed052Bc67545e967b8639bA',
  DESWAP_MATIC = '0x275eB6da9De810b2A3072d6Bbf4CD61f2269581E',
  DESWAP_MUMBAI = '0x275eB6da9De810b2A3072d6Bbf4CD61f2269581E',
  DESWAP_CUBE = '0xA0EaD99F785F09CEb1607cEF27181CedCECaA4c6',
  DESWAP_CUBETESTNET = '0xE037d12345dA559C2Ed052Bc67545e967b8639bA',
  DESWAP_ETHEREUM = '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
  PANCAKESWAP_TESTNET_01 = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  PANCAKESWAP_BSC = '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  PANCAKESWAP_ETH = '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  QUICKSWAP = '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
}

export interface AggregatorCurrency extends CurrencyCAKE, CurrencyDES, CurrencyQUICK, CurrencyUNI {}

export const AggregatorETHER = {
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_01]: ETHERCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_02]: ETHERCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_BSC]: ETHERCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_ETH]: ETHERCAKE,
  [AggregatorRouterEnum.DESPACE_TESTNET]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_TEST]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_CUBE]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_MUMBAI]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_MATIC]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_CUBETESTNET]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_ETHEREUM]: ETHERDES,
  [AggregatorRouterEnum.DESWAP_MAINNET]: ETHERDES,
  [AggregatorRouterEnum.QUICKSWAP]: ETHERQUICK,
  [AggregatorRouterEnum.UNISWAP_V2]: ETHERUNI,
}
export const AggregatorWETH = {
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_01]: WETHCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_02]: WETHCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_BSC]: WETHCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_ETH]: WETHCAKE,
  [AggregatorRouterEnum.DESPACE_TESTNET]: WETHDES,
  [AggregatorRouterEnum.DESWAP_TEST]: WETHDES,
  [AggregatorRouterEnum.DESWAP_CUBE]: WETHDES,
  [AggregatorRouterEnum.DESWAP_MUMBAI]: WETHDES,
  [AggregatorRouterEnum.DESWAP_MATIC]: WETHDES,
  [AggregatorRouterEnum.DESWAP_CUBETESTNET]: WETHDES,
  [AggregatorRouterEnum.DESWAP_ETHEREUM]: WETHDES,
  [AggregatorRouterEnum.DESWAP_MAINNET]: WETHDES,
  [AggregatorRouterEnum.QUICKSWAP]: WETHQUICK,
  [AggregatorRouterEnum.UNISWAP_V2]: WETHUNI,
}

export type IAggregatorTrade = TradeCAKE & TradeDES

export const AggregatorTrade = {
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_01]: TradeCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_TESTNET_02]: TradeCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_BSC]: TradeCAKE,
  [AggregatorRouterEnum.PANCAKESWAP_ETH]: TradeCAKE,
  [AggregatorRouterEnum.DESPACE_TESTNET]: TradeDES,
  [AggregatorRouterEnum.DESWAP_TEST]: TradeDES,
  [AggregatorRouterEnum.DESWAP_CUBE]: TradeDES,
  [AggregatorRouterEnum.DESWAP_MUMBAI]: TradeDES,
  [AggregatorRouterEnum.DESWAP_MATIC]: TradeDES,
  [AggregatorRouterEnum.DESWAP_CUBETESTNET]: TradeDES,
  [AggregatorRouterEnum.DESWAP_ETHEREUM]: TradeDES,
  [AggregatorRouterEnum.DESWAP_MAINNET]: TradeDES,
  [AggregatorRouterEnum.QUICKSWAP]: TradeQUICK,
  [AggregatorRouterEnum.UNISWAP_V2]: TradeUNI,
}
