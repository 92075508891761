import { ChainId } from '@pancakeswap/sdk'
import getChainID from '@/utils/getChainID'
import { useMemo } from 'react'
import styled from 'styled-components'
import { isAddress } from 'utils'
import LogoLoader from './LogoLoader'
import getTokenLogoURL from '@/utils/getTokenLogoURL'

const StyledLogo = styled(LogoLoader)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
`

export const CurrencyLogo: React.FC<{
  address?: string
  size?: string
}> = ({ address, size = '2.4rem', ...rest }) => {
  const src = useMemo(() => {
    const checksummedAddress = isAddress(address)
    if (checksummedAddress) {
      const chainID = +getChainID()
      if(chainID === ChainId.CUBE) {
        return `https://raw.githubusercontent.com/DeSpaceDeFi/tokenLists/cubeChain/images/${checksummedAddress}.png`
      }else{
        return getTokenLogoURL(checksummedAddress)
      }
    }
    return null
  }, [address])

  return <StyledLogo size={size} src={src} alt="token logo" {...rest} />
}

const DoubleCurrencyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface DoubleCurrencyLogoProps {
  address0?: string
  address1?: string
  size?: number
}

export const DoubleCurrencyLogo: React.FC<DoubleCurrencyLogoProps> = ({ address0, address1, size = 28 }) => {
  return (
    <DoubleCurrencyWrapper>
      {address0 && <div><CurrencyLogo address={address0} size={`${(size / 10).toString()}rem`} /></div>}
      {address1 && <div style={{position: 'absolute', right: '-1.7rem'}}><CurrencyLogo address={address1} size={`${(size / 10).toString()}rem`} /></div>}
    </DoubleCurrencyWrapper>
  )
}
