import { useState } from 'react'
import styles from './Tab.module.scss'

interface ITab {
  title: string
  render: JSX.Element
}

const Tab = ({ customClass = '', tabs }: { customClass?: string; tabs: ITab[] }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const selectTab = (tab: number) => {
    setSelectedTab(tab)
  }
  return (
    <div className={`${styles.tab_container} ${customClass}`}>
      <div>
        <div className={styles.tab_buttons}>
          {tabs.map((tab, index) => {
            return (
              <div key={index}>
                <button
                  onClick={() => selectTab(index)}
                  className={index === selectedTab ? styles.tab_buttons_active : ''}
                >
                  <span>{tab.title}</span>
                </button>
              </div>
            )
          })}
        </div>
        <div className={styles.tab_content}>{tabs[selectedTab].render}</div>
      </div>
    </div>
  )
}

export default Tab
