import Resolution from '@unstoppabledomains/resolution'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { NETWORK_URLS } from '@/config'
import { useAppDispatch } from '@/state'
import { setUserDomainName } from '@/state/user/actions'
import { userDomainName } from '@/state/user/hooks'
import { ChainId } from '@pancakeswap/sdk'

const resolution = new Resolution({
  sourceConfig: {
    uns: {
      locations: {
        Layer1: {
          url: NETWORK_URLS[ChainId.ETHEREUM],
          network: 'mainnet',
        },
        Layer2: {
          url: NETWORK_URLS[ChainId.MATIC],
          network: 'polygon-mainnet',
        },
      },
    },
  },
})

const useUnstoppableDomain = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const userDomain = userDomainName()

  const resolve = (domain: string, currency: string) => {
    resolution
      .addr(domain, currency)
      .then((address) => {
        return address
      })
      .catch(console.error)
  }

  const reverseUrl = (address: string): any => {
    return (
      resolution
        .reverse(address)
        .then((domain) => {
          // sreturn domain name
          return domain
        })
        // domain consists of the domain with reverse resolution to that address
        // use this domain in your application
        .catch(console.error)
    )
  }

  const getUserDomain = async () => {
    if (account) {
      const userDomain = await reverseUrl(account)
      dispatch(setUserDomainName(userDomain))
    }
  }
  return {
    resolve,
    reverseUrl,
    getUserDomain,
  }
}

export default useUnstoppableDomain
