import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'
import { ChainId, ETHER } from '@pancakeswap/sdk'
import convertToNativeTokenFromETH from '@/utils/convertToNativeCurrency'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'gDES',
    lpAddresses: {
      [ChainId.MAINNET]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      [ChainId.TESTNET]: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      [ChainId.MATIC]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      [ChainId.MUMBAI]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      [ChainId.CUBE]: '0xba4a5b413d34fa2040083b5315ec61a99455e5bb',
      [ChainId.CUBETESTNET]: '0xba4a5b413d34fa2040083b5315ec61a99455e5bb',
      [ChainId.ETHEREUM]: '0xba4a5b413d34fa2040083b5315ec61a99455e5bb',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 251,
    lpSymbol: `USDT-W${convertToNativeTokenFromETH(ETHER, +CHAIN_ID).symbol} LP`,
    lpAddresses: {
      [ChainId.MAINNET]: '0xf30E88d41700969Bc977daAAC0E0f740CDdA18Bc',
      [ChainId.TESTNET]: '',
      [ChainId.MATIC]: '0xea7C3B5299f6807f69952E2a2A33480bfC708a7f',
      [ChainId.MUMBAI]: '',
      [ChainId.CUBE]: '0xba4a5b413d34fa2040083b5315ec61a99455e5bb',
      [ChainId.CUBETESTNET]: '',
      [ChainId.ETHEREUM]: '0xC8dDb2DB15d80aA9ceD96c5cd47543c9D2E32eFb',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 252,
    lpSymbol: `USDC-W${convertToNativeTokenFromETH(ETHER, +CHAIN_ID).symbol} LP`,
    lpAddresses: {
      [ChainId.MAINNET]: '0xC53B5fb155c7e37020874896E324EAbeAF6d18bD',
      [ChainId.TESTNET]: '',
      [ChainId.MATIC]: '0x921586E35BC38e360dEeAD11eE5A9A1AAe74a25E',
      [ChainId.MUMBAI]: '',
      [ChainId.CUBE]: '0x6404adeacfc1cc54b153acec267d2f90972648e1',
      [ChainId.CUBETESTNET]: '',
      [ChainId.ETHEREUM]: '0x1207631FDa7300FeB5369d67BF503BFB7F0E0490',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wbnb,
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
