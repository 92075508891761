import { createReducer } from '@reduxjs/toolkit'
import { toggleAggregatorRouters, setBestRoute, setTradeReverse } from './actions'
import { AggregatorRouterEnum, AggregatorState, Field } from './types'
import { ChainId } from '@pancakeswap/sdk'
import { routerToName } from './hooks'

const initialState: AggregatorState = {
  routers: {
    [ChainId.MAINNET]: [
      {
        routerAddress: AggregatorRouterEnum.PANCAKESWAP_BSC,
        meta: routerToName[AggregatorRouterEnum.PANCAKESWAP_BSC],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.DESWAP_MAINNET,
        meta: routerToName[AggregatorRouterEnum.DESWAP_MAINNET],
        isChecked: true,
      },
    ],
    [ChainId.TESTNET]: [
      {
        routerAddress: AggregatorRouterEnum.PANCAKESWAP_TESTNET_01,
        meta: routerToName[AggregatorRouterEnum.PANCAKESWAP_TESTNET_01],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.DESPACE_TESTNET,
        meta: routerToName[AggregatorRouterEnum.DESPACE_TESTNET],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.DESWAP_TEST,
        meta: routerToName[AggregatorRouterEnum.DESWAP_TEST],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.PANCAKESWAP_TESTNET_02,
        meta: routerToName[AggregatorRouterEnum.PANCAKESWAP_TESTNET_02],
        isChecked: true,
      },
    ],
    [ChainId.MATIC]: [
      {
        routerAddress: AggregatorRouterEnum.DESWAP_MATIC,
        meta: routerToName[AggregatorRouterEnum.DESWAP_MATIC],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.PANCAKESWAP_BSC,
        meta: routerToName[AggregatorRouterEnum.PANCAKESWAP_BSC],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.DESWAP_MAINNET,
        meta: routerToName[AggregatorRouterEnum.DESWAP_MAINNET],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.QUICKSWAP,
        meta: routerToName[AggregatorRouterEnum.QUICKSWAP],
        isChecked: true,
      },
    ],
    [ChainId.MUMBAI]: [
      {
        routerAddress: AggregatorRouterEnum.DESWAP_MUMBAI,
        meta: routerToName[AggregatorRouterEnum.DESWAP_MUMBAI],
        isChecked: true,
      },
    ],
    [ChainId.CUBE]: [
      {
        routerAddress: AggregatorRouterEnum.DESWAP_CUBE,
        meta: routerToName[AggregatorRouterEnum.DESWAP_CUBE],
        isChecked: true,
      },
    ],
    [ChainId.CUBETESTNET]: [
      {
        routerAddress: AggregatorRouterEnum.DESWAP_CUBETESTNET,
        meta: routerToName[AggregatorRouterEnum.DESWAP_CUBETESTNET],
        isChecked: true,
      },
    ],
    [ChainId.ETHEREUM]: [
      {
        routerAddress: AggregatorRouterEnum.DESWAP_ETHEREUM,
        meta: routerToName[AggregatorRouterEnum.DESWAP_ETHEREUM],
        isChecked: true,
      },
      {
        routerAddress: AggregatorRouterEnum.UNISWAP_V2,
        meta: routerToName[AggregatorRouterEnum.UNISWAP_V2],
        isChecked: true,
      },
      // {
      //   routerAddress: AggregatorRouterEnum.UNISWAP_V3,
      //   meta: routerToName[AggregatorRouterEnum.UNISWAP_V3],
      //   isChecked: true,
      // },
    ],
  },
  bestRoute: AggregatorRouterEnum.DESPACE_TESTNET,
  reverseCheck: false,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(toggleAggregatorRouters, (state, { payload: { index, chainId, value } }) => {
    state.routers[chainId][index].isChecked = value
  })
  builder.addCase(setBestRoute, (state, { payload: { routerAddress } }) => {
    state.bestRoute = routerAddress
  })
  builder.addCase(setTradeReverse, (state, { payload: { currentState } }) => {
    state.reverseCheck = currentState
  })
})
