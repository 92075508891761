export interface Transactions {
  type: string
  token1: string
  token2: string
  value: number
  token1Amount: number
  token2Amount: number
  account: string
  time: number
}

export const transactions: Transactions[] = [
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'swap',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'add',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
  {
    type: 'remove',
    token1: 'DES',
    token2: 'BNB',
    value: 4760000,
    token1Amount: 2000000,
    token2Amount: 1020.24,
    account: '0x4e9be5c5e70a2b439655be97e772d761a27cb009',
    time: 4,
  },
]
