import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@pancakeswap/sdk'
import { AggregatorRouterEnum } from './types'
import { DerivedPairDataNormalized, PairDataNormalized, PairDataTimeWindowEnum, Field } from './types'

export const toggleAggregatorRouters = createAction<{
  index: number
  chainId: ChainId
  value: boolean
}>('aggregator/toggleAggregatorRouters')

export const setBestRoute = createAction<{
  routerAddress: string
}>('aggregator/setBestRoute')

export const setTradeReverse = createAction<{
  currentState: boolean
}>('aggregator/setTradeReverse')
