import React, { ReactNode } from 'react'
import styles from './Button.module.scss'

import Image from 'next/image'

interface Props {
  type?: 'purple-400' | 'purple-300' | 'purple-200' | 'red-300' | 'transparent'
  children: React.ReactNode
  iconPrefix?: string | ReactNode
  iconSuffix?: string | ReactNode
  className?: string
  style?: Object
  disabled?: boolean
  id?: string
  onClick?: (event?: any) => void
}

const Button = ({
  type = 'purple-200',
  children,
  onClick,
  className,
  iconPrefix,
  iconSuffix,
  style,
  id,
  disabled = false,
}: Props) => {
  const startIcon = () => {
    if (!!iconPrefix) {
      if (typeof iconPrefix === 'string') {
        return (
          <figure className={styles.button_icon}>
            <Image src={iconPrefix} layout="fill" alt="" />
          </figure>
        )
      } else {
        return iconPrefix
      }
    }
    return null
  }

  const endIcon = () => {
    if (!!iconSuffix) {
      if (typeof iconSuffix === 'string') {
        return (
          <figure className={styles.button_icon}>
            <Image src={iconSuffix} layout="fill" alt="" />
          </figure>
        )
      } else {
        return iconSuffix
      }
    }

    return null
  }

  return (
    <button
      onClick={onClick}
      className={`${styles[type]} ${className} ${styles.button}`}
      data-type={type}
      style={style}
      disabled={disabled}
      id={id}
    >
      {startIcon()}
      {children}
      {endIcon()}
    </button>
  )
}

export default Button
