import { ChainId } from '@pancakeswap/sdk'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { CHAIN_ID } from './networks'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  [VaultKey.CakeVault]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.des.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.des,
    earningToken: serializedTokens.des,
    contractAddress: {
      [ChainId.MAINNET]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      [ChainId.TESTNET]: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
      [ChainId.MATIC]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      [ChainId.MUMBAI]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      [ChainId.CUBE]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      [ChainId.CUBETESTNET]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      [ChainId.ETHEREUM]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 274,
    stakingToken: serializedTokens.des,
    earningToken: serializedTokens.usdt,
    contractAddress: {
      [ChainId.MAINNET]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.TESTNET]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.MATIC]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.MUMBAI]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.CUBE]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.CUBETESTNET]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
      [ChainId.ETHEREUM]: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.3865',
    deployedBlockNumber: 16526267,
    version: 3,
  },
  ].filter((p) => !!p.contractAddress[CHAIN_ID])


export default pools
