import { ChainId } from '@pancakeswap/sdk'
import { StaticJsonRpcProvider } from '@ethersproject/providers'
import getRpcUrl from 'utils/getRpcUrl'
import { ethers } from 'ethers'
import { connectorLocalStorageKey } from '@pancakeswap/uikit'
import { connectorsByName } from './web3React'
import { NETWORK_URLS } from '@/config'

const RPC_URL = getRpcUrl()

export const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL)

export const providers: {
    [chainId in ChainId]?: any
} = {
    [ChainId.MAINNET]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.MAINNET]),
    [ChainId.TESTNET]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.TESTNET]),
    [ChainId.MATIC]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.MATIC]),
    [ChainId.MUMBAI]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.MUMBAI]),
    [ChainId.CUBE]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.CUBE]),
    [ChainId.CUBETESTNET]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.CUBETESTNET]),
    [ChainId.ETHEREUM]: new ethers.providers.JsonRpcProvider(NETWORK_URLS[ChainId.ETHEREUM]),
}

export const getProvider = async () => {
    if(typeof window !== "undefined"){
        const connectorID = window.localStorage.getItem(connectorLocalStorageKey) || 'injected'
        const connectorOrGetConnector = connectorsByName[connectorID]
        const connector = typeof connectorOrGetConnector !== 'function' 
            ? connectorsByName[connectorID] 
            : await connectorOrGetConnector()
        const provider = typeof connector != 'function' && connector ? await connector.getProvider() : window.ethereum
        return provider
    }else{
        return null
    }
}

export default null
