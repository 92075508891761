import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import {
  toogleCollectionModal,
  fetchCollection,
  addUserCollections,
  updateStateNfts,
  saveNftPayload,
  updateMetaData,
  updateNftRes,
} from './actions'
import { AddCollectionPayload, Collection, CollectionPayload, NftState } from './types'
import { useCallback, useEffect, useState } from 'react'
import { apiCall, uploadFile } from './helpers'
import {
  use720NftCreateContract,
  use721NftMarketPlaceContract,
  use1155NftCreateContract,
  use1155NftMarketPlaceContract,
} from '@/hooks/useContract'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useRouter } from 'next/router'
import { Contract, ethers, BigNumber } from 'ethers'
import { calculateGasMargin } from '@/utils'
import { useGasPrice } from '../user/hooks'
import abi from '@/config/abi/er721-abi.json'
import { DES_TOKENS } from '@/config/constants'
import ERC20_ABI from '@/config/abi/erc20.json'
import { formatUnits } from '@ethersproject/units'
import { getAddress } from '@/utils/addressHelpers'
import addresses from 'config/constants/contracts'

export function useNftState(): AppState['nft'] {
  return useSelector<AppState, AppState['nft']>((state) => state.nft)
}

export const useUserCollections = (): Array<Collection> => {
  return useSelector((state: NftState) => state.userCollections)
}

export function useNftActionHandlers(): {
  toggleAddCollectionDialog: (payload: AddCollectionPayload) => void
  getAllCollections: (page?: number, limit?: number) => void
  fetchUserCollections: (account: string) => void
  updateUserCollections: (data, page?: number, limit?: number) => void
  exploreNfts: (payload: any) => void
  loadingStates: {
    getAllCollections: boolean
    fetchUserCollections: boolean
    updateUserCollections: boolean
    exploreNfts: boolean
  }
} {
  const [loadingStates, setLoadingStates] = useState({
    getAllCollections: false,
    fetchUserCollections: false,
    updateUserCollections: false,
    exploreNfts: false,
  })
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const nftState = useNftState()
  const toggleAddCollectionDialog = useCallback(
    (payload: AddCollectionPayload) => {
      dispatch(toogleCollectionModal({ data: payload }))
    },
    [dispatch],
  )

  const getAllCollections = useCallback(
    async (page = 1, limit = 15) => {
      // const {collectionsMeta: {page, limit}} = nftState
      try {
        const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections?page=${page}&limit=${limit}&chainId=${chainId}`
        const data = await apiCall(url, 'GET')
        dispatch(fetchCollection({ data: data.data }))
        dispatch(
          updateMetaData({
            data: { module: 'userCollectionsMeta', payload: { page: data.page, limit: data.limit, count: data.count } },
          }),
        )
      } catch (error) {
        console.log(error)
      }
    },
    [dispatch],
  )

  const fetchUserCollections = useCallback(
    async (account) => {
      const {
        userCollectionsMeta: { page, limit },
      } = nftState
      try {
        const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections/${account}/address?page=${page}&limit=${limit}&chainId=${chainId}`
        const data = await apiCall(url, 'GET')
        if (data.status === 404) {
          return
        } else {
          dispatch(addUserCollections({ data: data.data }))
          dispatch(
            updateMetaData({
              data: {
                module: 'userCollectionsMeta',
                payload: { page: data.page, limit: data.limit, count: data.count },
              },
            }),
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    [dispatch],
  )

  const updateUserCollections = (data) => {
    dispatch(addUserCollections({ data }))
  }

  const exploreNfts = useCallback(
    async (payload) => {
      const { status, page, limit, chainId, money, min, max, action = 'new' } = payload
      setLoadingStates({ ...loadingStates, exploreNfts: true })

      const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/nft`)
      const params = { status, page, limit, chainId, money, min, max }
      Object.keys(params).forEach((key) => params[key] && url.searchParams.append(key, params[key]))

      const collectionUrl = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections?page=${page}&limit=${limit}&chainId=${chainId}`

      try {
        let data = await apiCall(url, 'GET')
        const formatedData = data.data.map((nft) => {
          return {
            ...nft,
            uri: nft.uri.includes('https') ? nft.uri : 'https://ipfs.io/ipfs/' + nft.uri,
          }
        })
        const collections = await apiCall(collectionUrl, 'GET')

        dispatch(updateStateNfts({ data: formatedData, action }))
        dispatch(fetchCollection({ data: collections.data }))
        dispatch(
          updateMetaData({
            data: {
              module: 'collectionsMeta',
              payload: { page: collections.page, limit: collections.limit, count: collections.count },
            },
          }),
        )
        dispatch(
          updateMetaData({
            data: { module: 'nftsMeta', payload: { page: data.page, limit: data.limit, count: data.count } },
          }),
        )
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingStates({ ...loadingStates, exploreNfts: false })
      }
    },
    [dispatch],
  )

  return {
    toggleAddCollectionDialog,
    getAllCollections,
    fetchUserCollections,
    updateUserCollections,
    exploreNfts,
    loadingStates,
  }
}

export const useCollections = () => {
  const { account, library } = useActiveWeb3React()
  const { fetchUserCollections } = useNftActionHandlers()
  const create720NftContract = use720NftCreateContract()
  const create1155NftContract = use1155NftCreateContract()
  const marketPlaceContract = use721NftMarketPlaceContract()
  const marketplace1155Contract = use1155NftMarketPlaceContract()
  const { pendingNft } = useNftState()
  const router = useRouter()
  const [newContract, setNewContract] = useState<string>('')
  const [newTradeDetails, setNewTradeDetails] = useState<{
    id: string
    address: string
  }>({ id: '', address: '' })
  const dispatch = useDispatch<AppDispatch>()

  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft`

  const mintNft = async (address, contractInstance) => {
    if (address && newContract) {
      try {
        const mintRes = await contractInstance.mint(account)
        await mintRes.wait(1)
        const receipt = await library.getTransactionReceipt(mintRes.hash)
        let logs = receipt.logs
        return ethers.BigNumber.from(logs[0].topics[3]).toString()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const initEvents = () => {
    try {
      /**
       * * Contract events for both 1155 and 721
       */
      create720NftContract.on('Created', async (creator, contractAddress) => {
        const address = await contractAddress
        setNewContract(address)
      })
      create1155NftContract.on('Created', async (creator, contractAddress) => {
        const address = await contractAddress
        setNewContract(address)
      })
      marketPlaceContract.on('AuctionCreated', async (address, auctionId) => {
        setNewTradeDetails({ id: ethers.BigNumber.from(auctionId).toString(), address })
      })
      marketPlaceContract.on('InstantCreated', async (address, tradeId) => {
        setNewTradeDetails({ id: ethers.BigNumber.from(tradeId).toString(), address })
      })
      marketPlaceContract.on('NewBuy', async (buyer, instId, price) => {
        // Add new buy function
      })
      marketPlaceContract.on('NewBid', async (buyer, instId, price) => {
        // Add new bid functionality
      })
      marketplace1155Contract.on('AuctionCreated', async (address, auctionId) => {
        setNewTradeDetails({ id: ethers.BigNumber.from(auctionId).toString(), address })
      })
      marketplace1155Contract.on('InstantCreated', async (address, tradeId) => {
        setNewTradeDetails({ id: ethers.BigNumber.from(tradeId).toString(), address })
      })
      marketplace1155Contract.on('NewBuy', async (buyer, instId, price) => {
        // Add new buy for 1155 contract
      })
      marketplace1155Contract.on('NewBid', async (buyer, instId, price) => {
        // Add new bid
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const updateNftItem = async (tradeDetails, nftPayload) => {
      const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/${nftPayload.contractAddress}/nft-address`

      /**
       * * Its important to check to make sure that its the current user that created the nft before we update it
       */
      if (tradeDetails.address === nftPayload.creatorAddress) {
        try {
          const res = await apiCall(url, 'PUT', {
            ...nftPayload,
            auctionId: nftPayload.sellMethod !== 'fixed' ? tradeDetails.id : null,
            instantTradeId: nftPayload.sellMethod === 'fixed' ? tradeDetails.id : null,
          })
          dispatch(updateNftRes({ data: res }))
        } catch (error) {
          console.log(error)
        } finally {
          setNewTradeDetails({ id: '', address: '' })
        }
      }
    }

    if (newTradeDetails.id) {
      updateNftItem(newTradeDetails, pendingNft)
    }
  }, [newTradeDetails])

  useEffect(() => {
    const completeNftCreation = async (address: string, nftPayload) => {
      let payload = await getPending()
      if (account) {
        const nftContractInstance = new Contract(newContract, abi, library.getSigner())
        try {
          if (payload.mintNft) {
            const tokenId = await mintNft(address, nftContractInstance)
            payload = {
              ...payload,
              tokenIds: [
                [
                  {
                    owner: address,
                    id: tokenId,
                  },
                ],
              ],
            }
          }
          await apiCall(url, 'POST', {
            ...payload,
            supply: payload.supply.toString(),
            contractAddress: address,
          })
          router.push(`/nft/${address}`)
        } catch (error) {
          console.log(error)
        }
      }
    }

    const getPending = async () => {
      return await pendingNft
    }

    if (newContract) {
      completeNftCreation(newContract, pendingNft)
    }
  }, [newContract])

  useEffect(() => {
    if (account) {
      fetchUserCollections(account)
    }
  }, [account])

  useEffect(() => {
    initEvents()
    return () => {
      initEvents()
    }
  }, [])
}

export const useCreateCollection = () => {
  const { userCollections } = useNftState()
  const { updateUserCollections } = useNftActionHandlers()
  const [loading, setLoading] = useState<boolean>(false)
  const [values, setValues] = useState<CollectionPayload>({
    image: '',
    name: '',
    description: '',
    featuredUrl: '',
    shortUrl: '',
    network: '',
    file: null,
    chainId: 56,
  })
  const [errors, setErrors] = useState<CollectionPayload>({
    image: '',
    name: '',
    description: '',
    featuredUrl: '',
    shortUrl: 'Will be used as public URL',
    network: '',
    file: '',
    chainId: null,
  })
  const url = `${process.env.NEXT_PUBLIC_API_URL}/nft/collections`

  const createCollection = async (payload: CollectionPayload) => {
    setLoading(true)
    try {
      const { uri } = await uploadFile(payload.file)
      delete payload.file
      delete payload.image
      const response = await apiCall(url, 'POST', {
        ...payload,
        bannerUrl: uri,
        profileUrl: uri,
      })
      const data = [...userCollections, response]
      updateUserCollections(data)
      return true
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const validate = (event: React.ChangeEvent<HTMLInputElement>) => {
    //A function to validate each input values
    let errorObj = { ...errors }
    if (!event.target.value) {
      errorObj = {
        ...errors,
        [event.target.name]: 'Field is required',
      }
    } else {
      errorObj = {
        ...errors,
        [event.target.name]: '',
      }
    }
    setErrors(errorObj)
  }

  const checkFormValidity = (): boolean => {
    const { shortUrl, image, name, chainId } = values

    if (shortUrl && image && name && chainId && !errors.shortUrl) {
      return true
    }
    return false
  }

  const hasError = (): boolean => {
    const { featuredUrl, image, name, chainId } = errors
    if (featuredUrl || image || name || chainId) {
      return true
    }
    return false
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setValues({ ...values, [name]: value })
  }

  const handleFileChange = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.currentTarget.files ? e.currentTarget.files[0] : null)
    setValues({
      ...values,
      file: formData,
      image: URL.createObjectURL(e.currentTarget.files ? e.currentTarget.files[0] : ''),
    })
  }

  return {
    loading,
    createCollection,
    values,
    setErrors,
    setValues,
    errors,
    validate,
    checkFormValidity,
    hasError,
    handleChange,
    handleFileChange,
  }
}

export const useCreateNft = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const create720NftContract = use720NftCreateContract()
  const create1155NftContract = use1155NftCreateContract()
  const dispatch = useDispatch<AppDispatch>()

  const createNewNft = async (payload: any) => {
    setLoading(true)
    try {
      const { uri } = await uploadFile(payload.formData)
      let contractResponse = {} as any
      if (payload.tokenStandard === 721) {
        contractResponse = await create720NftContract.create(payload.name, payload.symbol, uri, payload.royalty)
        contractResponse.wait(1)
      } else {
        contractResponse = await create1155NftContract.create(payload.name, payload.symbol, uri, payload.royalty)
        contractResponse.wait(1)
      }

      delete payload.formData
      delete payload.img
      delete payload.collection
      delete payload.instantTradeId
      delete payload.auctionId
      dispatch(
        saveNftPayload({
          data: {
            ...payload,
            contractAddress: '',
            ownerAddress: contractResponse?.from,
            txHash: contractResponse?.hash,
            uri,
            coin: 'BSC',
          },
        }),
      )
      // return false
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 6000)
    }
  }

  return {
    loading,
    createNewNft,
  }
}

export const useMarketPlace = () => {
  const marketPlaceContract = use721NftMarketPlaceContract()
  const marketplace1155Contract = use1155NftMarketPlaceContract()
  const gasPrice = useGasPrice()
  const { library, account, chainId } = useActiveWeb3React()

  const checkApproval = async (contractRes, tokenStandard) => {
    let isApprovedForAll = false
    if (tokenStandard === '1155') {
      isApprovedForAll = await contractRes.isApprovedForAll(account, getAddress(addresses.marketplace1155))
    } else {
      isApprovedForAll = await contractRes.isApprovedForAll(account, getAddress(addresses.marketplace721))
    }

    return isApprovedForAll
  }

  const getAutionIds = async () => {
    try {
      // const aucIds = await marketPlaceContract.aucIds()
      // const instIds = await marketPlaceContract.instIds()
      const listInstant = await marketPlaceContract.listInstant('0x089843c9A033647C5CcE8574C97A51C0101d62b7', 1, 1, 1)
      // return aucIds
    } catch (error) {
      console.log(error)
    }
  }
  /**
   *
   * @param address address of the 721 NFT to be auctioned
   * @param tokenId  the token id of the 721 NFT to be auctioned,
   * @param price  the price of the nft being auctioned,
   * @param secondsBeforeAuction number of seconds remaining before auction starts,
   * @param auctionDuration auction duration in seconds,
   * @param isFixedTime  true of false to signify if this is a fixed time auction or not (definition below),
   * @param moneyType Money type. Between 0 and 1 to choose if the trade should be done with DES or BNB respectively
   * @param tokenStandard tokenStandard that determines if we are trading a 721 or 1155
   * @param from address of seller [for 1155 contracts]
   * @param numberOfTokens number of tokens to be traded [this would be one since we list items individually]
   * @returns
   */

  const createAuction = async (
    address: string,
    tokenId: string,
    price: any,
    secondsBeforeAuction: any,
    auctionDuration: any,
    isFixedTime: boolean,
    moneyType: 0 | 1,
    tokenStandard,
    from,
    numberOfTokens,
  ) => {
    try {
      const gasLimitEstimate = BigNumber.from('1388024')
      const gasLimit = calculateGasMargin(gasLimitEstimate)

      const contractRes = new Contract(address, abi, library.getSigner())
      const approvalRes = await checkApproval(contractRes, tokenStandard)
      if (!approvalRes) {
        // Approve the marketplace
        await contractRes.setApprovalForAll(
          tokenStandard === '1155' ? getAddress(addresses.marketplace1155) : getAddress(addresses.marketplace721),
          true,
        )
      }

      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.listAuction(
          from,
          address,
          tokenId,
          numberOfTokens,
          price,
          secondsBeforeAuction,
          auctionDuration,
          isFixedTime,
          moneyType,
          {
            gasLimit: gasLimit,
            gasPrice,
          },
        )
      } else {
        response = await marketPlaceContract.listAuction(
          address,
          tokenId,
          price,
          secondsBeforeAuction,
          auctionDuration,
          isFixedTime,
          moneyType,
          {
            gasLimit: gasLimit,
            gasPrice,
          },
        )
      }

      await response.wait(1)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getSingleAuction = async (auctionId, tokenStandard) => {
    try {
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.idToAuction(auctionId)
      } else {
        response = await marketPlaceContract.idToAuction(auctionId)
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getAmountForNextBid = async (id, tokenStandard) => {
    try {
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.amountForNextBid(id)
      } else {
        response = await marketPlaceContract.amountForNextBid(id)
      }
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getTimeLeftForBid = async (id, tokenStandard) => {
    try {
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.timeLeftForBid(id)
      } else {
        response = await marketPlaceContract.timeLeftForBid(id)
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @param autionId auction id,
   * @param secondsLeft how many seconds remaining for auction to end,
   * @param isFixedTime true of false if auction should be fix timed
   */
  const updateAuctionEndTime = async (autionId, secondsLeft, isFixedTime) => {
    try {
      // const response = await marketPlaceContract.updateAuctionEnd(autionId, secondsLeft, isFixedTime)
      // return response
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @param _aucId auction id,
   * @param amount amount which should either be 0 for auctions with money type being BNB or not lower than the amount returned from the read method **amountForNextBid**,
   * @param value BNB value which must be 0 for auctions with money type being DES or not lower than the amount returned from the read method **amountForNextBid**
   * @param moneyType Money type. Between 0 and 1 to choose if the trade should be done with DES or BNB respectively
   * @param tokenStandard either 721 or 1155 tokens
   * @returns
   */
  const makeBid = async (_aucId, amount, value, moneyType, tokenStandard) => {
    try {
      /**
       * CALL ERC20 APPROVAL
       */

      if (moneyType === 0) {
        const contractRes = new Contract(DES_TOKENS[chainId], ERC20_ABI, library.getSigner())
        const allowance = await contractRes.allowance(account, getAddress(addresses.marketplace721))
        if (formatUnits(allowance, 'ether') < formatUnits(amount, 'ether')) {
          await contractRes.approve(getAddress(addresses.marketplace721), amount)
        }
      }
      const gasLimitEstimate = BigNumber.from('1388024')
      const gasLimit = calculateGasMargin(gasLimitEstimate)
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.bid(_aucId, amount, {
          value,
          gasLimit: gasLimit,
          gasPrice,
        })
      } else {
        response = await marketPlaceContract.bid(_aucId, amount, {
          value,
          gasLimit: gasLimit,
          gasPrice,
        })
      }

      await response.wait(1)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const closeAuction = async (id, tokenStandard) => {
    try {
      let tx = {} as any
      if (tokenStandard === '1155') {
        tx = await marketplace1155Contract.closeAuction(id)
      } else {
        tx = await marketPlaceContract.closeAuction(id)
      }
      await tx.wait(1)
      return tx
    } catch (error) {
      console.log(error)
    }
  }

  // Instant trade methods
  const getContractInstantTrades = async () => {
    try {
      // const response = await marketPlaceContract.instIds()
      // return response
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 
   * @param tradeId Id of instant trade
   * @returns {
   *    address of the seller,
        address of buyer. if no buyer, shows the zero address,
        address of NFT being sold,
        token id of NFT,
        floor price amount,
        buy price amount,
        Money type. Between 0 and 1 to choose if the trade should be done with DES or BNB respectively,
        true or false to show if this instant trade has been closed
   * }
      for 1155 tokens
   * @returns { 
        address of the seller,
        address of buyer. if no buyer, shows the zero address,
        address of NFT being sold,
        token id of NFT,
        number of tokens
        floor price amount,
        buy price amount,
        Money type. Between 0 and 1 to choose if the trade should be done with DES or BNB respectively,
        true or false to show if this instant trade has been closed
   * }
   */
  const getSingleInstantTrade = async (tradeId, tokenStandard) => {
    try {
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.idToInstant(tradeId)
      } else {
        response = await marketPlaceContract.idToInstant(tradeId)
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @param address address of the 721 / 1155 nft to be sold
   * @param tokenId token ID of NFT
   * @param price price of NFT
   * @param moneyType  Between 0 and 1 to choose if the trade should be done with DES or BNB respectively
   * @param from Seller address [exculusively for 1155 contracts]
   * @param numberOfTokens number of tokens to be traded [exculusively for 1155 contracts]
   * @param tokenStandard either 721 or 1155
   * @returns
   */
  const createInstantTrade = async (address, tokenId, price, moneyType, from, numberOfTokens, tokenStandard) => {
    try {
      const contractRes = new Contract(address, abi, library.getSigner())
      const approvalRes = await checkApproval(contractRes, tokenStandard)
      if (!approvalRes) {
        // Approve the marketplace
        await contractRes.setApprovalForAll(
          tokenStandard === '1155' ? getAddress(addresses.marketplace1155) : getAddress(addresses.marketplace721),
          true,
        )
      }
      const gasLimitEstimate = BigNumber.from('1388024')
      const gasLimit = calculateGasMargin(gasLimitEstimate)
      let tx = {} as any
      if (tokenStandard === '1155') {
        tx = await marketplace1155Contract.listInstant(from, address, tokenId, numberOfTokens, price, moneyType, {
          gasLimit: gasLimit,
          gasPrice,
        })
      } else {
        tx = await marketPlaceContract.listInstant(address, tokenId, price, moneyType, {
          gasLimit: gasLimit,
          gasPrice,
        })
      }

      await tx.wait(1)
      return tx
    } catch (error) {
      console.log(error)
    }
  }

  const getMinTradeAmountTrade = async (id, tokenStandard = '721') => {
    try {
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.amountForBuy(id)
      } else {
        response = await marketPlaceContract.amountForBuy(id)
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @param instantTradeId Instant trade Id
   * @param amount  amount which should either be 0 for trades with money type being BNB or not lower than the amount returned from the read method **amountForBuy**,
   * @param BNB BNB value which must be 0 for instant trades with money type being DES or not lower than the amount returned from the read method **amountForBuy**
   * @returns
   */
  const buyInstantTrade = async (_instId, amount, value, moneyType, tokenStandard = '721') => {
    try {
      /**
       * CALL ERC20 APPROVAL
       */

      if (moneyType === 0) {
        const contractRes = new Contract(DES_TOKENS[chainId], ERC20_ABI, library.getSigner())
        const allowance = await contractRes.allowance(account, getAddress(addresses.marketplace721))
        if (formatUnits(allowance, 'ether') < formatUnits(amount, 'ether')) {
          await contractRes.approve(getAddress(addresses.marketplace721), amount)
        }
      }

      const gasLimitEstimate = BigNumber.from('1388024')
      const gasLimit = calculateGasMargin(gasLimitEstimate)
      let response = {} as any
      if (tokenStandard === '1155') {
        response = await marketplace1155Contract.buyInstant(_instId, amount, {
          value,
          gasLimit: gasLimit,
          gasPrice,
        })
      } else {
        response = await marketPlaceContract.buyInstant(_instId, amount, {
          value,
          gasLimit: gasLimit,
          gasPrice,
        })
      }

      await response.wait(1)

      return response
    } catch (error) {
      console.log(error)
    }
  }
  const closeInstantTrade = async (tradeId, tokenStandard = '721') => {
    try {
      let tx = {} as any
      if (tokenStandard === '1155') {
        tx = await marketplace1155Contract.closeAndCancelInstant(tradeId)
      } else {
        tx = await marketPlaceContract.closeAndCancelInstant(tradeId)
      }

      await tx.wait(1)
      return tx
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getAutionIds,
    createAuction,
    getSingleAuction,
    getAmountForNextBid,
    getTimeLeftForBid,
    updateAuctionEndTime,
    makeBid,
    closeAuction,
    getContractInstantTrades,
    getSingleInstantTrade,
    createInstantTrade,
    getMinTradeAmountTrade,
    buyInstantTrade,
    closeInstantTrade,
    checkApproval,
  }
}
