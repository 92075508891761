import { useState, useEffect } from 'react'
import Image from 'next/image'
import { CHAIN_ID } from '@/config/constants/networks'
import { CHAIN_ID_KEY, NETWORK_DETAILS, SUPPORTED_CHAIN_IDS } from '@/config'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import isWindow from '@/utils/isWindow'
import styles from './CustomInput.module.scss'

interface Props {
  icon?: string
  placeholder?: string
  value?: string | number
  name?: string
  label?: string
  type?: 'text' | 'number' | 'password' | 'date'
  className?: string
  required?: boolean
  description?: string
  prepend?: string
  append?: string
  networkSelector?: boolean
  items?: Array<{ name: string | number; icon: string; value: string | number }>
  networkValue?: string | number
  network?: string
  hint?: string
  disabled?: boolean
  hasError?: boolean
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onOptionChange?: (e: any) => void
}

interface Network {
  label: string
  icon: string
  active: boolean
}

const CustomInput = ({
  name,
  type = 'text',
  icon,
  label,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  className,
  required,
  description,
  prepend,
  networkValue,
  append,
  hint,
  hasError = false,
  networkSelector,
  items = [],
  onOptionChange,
  disabled,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<Boolean>(false)
  const [selectedChainId, setSelectedChainId] = useState<number | string>('')

  useEffect(() => {
    if (networkValue) {
      setSelectedChainId(networkValue)
    }
  }, [])

  const changeActive = (_chainId: number) => {
    setSelectedChainId(_chainId)

    typeof onOptionChange === 'function' && onOptionChange(_chainId)
    setMenuOpen(false)
  }

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(!menuOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setMenuOpen(false)
    }
    if (networkSelector) {
      window.addEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={`input ${className}`}>
      {!!label && (
        <label className="input_label" htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
      )}

      {!!description && <p className="input_description">{description}</p>}
      <div className="input_wrapper">
        {!!icon && (
          <figure className="input_icon">
            <Image src={icon} layout="fill" alt="icon" />
          </figure>
        )}
        {!!prepend && <p className="input_prepend">{prepend}</p>}
        <input
          className="input_field"
          type={type}
          data-icon={!!icon}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          name={name}
          disabled={disabled}
        />
        {networkSelector && (
          <button onClick={toggleMenu} className={styles.input_select}>
            <div className={styles.input_select_network}>
              <Image
                src={items.find((item) => item.value === selectedChainId)?.icon || '/svgs/icon-bnb.svg'}
                layout="fill"
                alt="icon"
              />
            </div>
            <div className={styles.input_select_dropdown}>
              <Image src="/svgs/chevron-bottom.svg" layout="fill" alt="icon" />
            </div>
          </button>
        )}
        {!!append && <p className="input_append">{append}</p>}
      </div>
      {!!hint && <p className={`input_hint ${hasError ? 'input_hint__error' : ''}`}>{hint}</p>}
      {menuOpen && (
        <div className={styles.input_select_menu}>
          {items.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className={`${styles.input_select_menu_item}
                  ${item.value === selectedChainId && styles.input_select_menu_item_active}`}
                onClick={() => changeActive(item.value)}
              >
                <Image
                  src={item.icon}
                  layout="fixed"
                  height="20rem"
                  width="20rem"
                  alt={item.name}
                  className={styles.input_select_menu_icon}
                />
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CustomInput
