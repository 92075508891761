import { HelpIcon, useTooltip, Box, BoxProps, Placement } from '@pancakeswap/uikit'
import styled from 'styled-components'

interface Props extends BoxProps {
  text: string | React.ReactNode
  placement?: Placement
  size?: string
  variant?: string
}

const QuestionWrapper = styled.div`
  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionHelper: React.FC<Props> = ({
  text,
  placement = 'right-end',
  size = '1.6rem',
  variant = 'one',
  ...props
}) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' })

  return (
    <Box {...props}>
      {tooltipVisible && tooltip}
      <QuestionWrapper ref={targetRef}>
        {variant === 'one' ? (
          <HelpIcon color="textSubtle" width={size} />
        ) : (
          <img src="/svgs/info-icon.svg" alt="tootlip" />
        )}
      </QuestionWrapper>
    </Box>
  )
}

export default QuestionHelper
