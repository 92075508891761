import { DES_TOKEN } from '@/state/swap/constants'
import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET, MATIC, MUMBAI, CUBE, CUBETESTNET, ETHEREUM } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  cake: new Token(
    MAINNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  des: new Token(
    MAINNET,
    '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  lead: new Token(MAINNET, '0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66', 18, 'LEAD', 'Lead Protocol'),
  busd: new Token(
    MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  dai: new Token(
    MAINNET,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://www.makerdao.com/',
  ),
  usdt: new Token(
    MAINNET,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  btcb: new Token(
    MAINNET,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'Binance BTC',
    'https://bitcoin.org/',
  ),
  ust: new Token(
    MAINNET,
    '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    18,
    'UST',
    'Wrapped UST Token',
    'https://mirror.finance/',
  ),
  eth: new Token(
    MAINNET,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'ETH',
    'Binance-Peg Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    MAINNET,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  syrup: new Token(
    MAINNET,
    '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  uni: new Token(
    MAINNET,
    '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    18,
    'UNI',
    'Binance-Peg Uniswap Token',
    'https://uniswap.org/',
  ),
} as const)

export const testnetTokens = defineTokens({
  wbnb: new Token(
    TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  des: new Token(TESTNET, DES_TOKEN, 18, 'DES', 'Despace Protocol Token', 'https://despace.io/'),
  cake: new Token(
    TESTNET,
    '0xFa60D973F7642B748046464e165A65B7323b0DEE',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    TESTNET,
    '0x3d8f2Ada8e97e4eF19e4ccBf6ec1Ca52900406aA',
    18,
    'USDT',
    'TETHER USD',
    'https://pancakeswap.finance/',
  ),
  usdc: new Token(
    TESTNET,
    '0xb448B701807E644f141a4E4a269aD2F567526505',
    18,
    'USDC',
    'USD Coin',
    'https://pancakeswap.finance/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
} as const)

export const maticTokens = defineTokens({
  wbnb: new Token(MATIC, '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', 18, 'WMatic', 'Wrapped Matic'),
  usdt: new Token(MATIC, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 6, 'USDT', 'Tether USDT'),
  dai: new Token(MATIC, '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', 18, 'DAI', 'DAI Stablecoin'),
  usdc: new Token(MATIC, '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC', 'USD Coin'),
  btc: new Token(MATIC, '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', 8, 'WBTC', 'Wrapped BTC'),
  quick: new Token(MATIC, '0x831753DD7087CaC61aB5644b308642cc1c33Dc13', 18, 'QUICK', 'Quickswap'),
  miMatic: new Token(MATIC, '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1', 18, 'miMATIC', 'miMATIC'),
  des: new Token(
    MATIC,
    '0xa062fc09cA6bdeb2f6E3b77E1d4e09C42C964742',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  busd: new Token(
    MATIC,
    '0x87ff96aba480f1813aF5c780387d8De7cf7D8261',
    18,
    'BUSD',
    'Wrapped BUSD',
    'https://binance.com/',
  ),
  eth: new Token(MATIC, '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18, 'ETH', 'Ether'),
} as const)

export const mumbaiTokens = defineTokens({
  wbnb: new Token(
    MUMBAI,
    '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    18,
    'WMatic',
    'Wrapped Matic',
    'https://www.polygon.network/',
  ),
  des: new Token(
    MUMBAI,
    '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  dai: new Token(
    MUMBAI,
    '0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1',
    18,
    'DAI',
    'DAI Stablecoin',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    MUMBAI,
    '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
    6,
    'USDT',
    'TETHER USD',
    'https://www.paxos.com/usdt',
  ),
  eth: new Token(MUMBAI, '0x714550C2C1Ea08688607D86ed8EeF4f5E4F22323', 18, 'ETH', 'Ether', 'https://ethereum.org/'),
} as const)

export const cubeTokens = defineTokens({
  wbnb: new Token(
    CUBE,
    '0x9D3F61338d6Eb394e378D28C1Fd17d5909aC6591',
    18,
    'WCUBE',
    'Wrapped CUBE',
    'https://www.cube.network/',
  ),
  usdt: new Token(CUBE, '0x79F1520268A20c879EF44d169A4E3812D223C6de', 18, 'USDT', 'Cube-peg USDT'),
  usdc: new Token(CUBE, '0x00f0D8595797943c12605cD59bc0D9f63D750cCf', 18, 'USDC', 'Cube-peg USDC'),
  des: new Token(
    CUBE,
    '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  cake: new Token(
    CUBETESTNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'Pancakeswap token',
    'https://pancakeswap.finance/',
  ),
} as const)

export const cubeTestnetTokens = defineTokens({
  wbnb: new Token(
    CUBETESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WCUBE',
    'Wrapped CUBE',
    'https://www.cube.network/',
  ),
  des: new Token(
    CUBETESTNET,
    '0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  cake: new Token(
    CUBETESTNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'Pancakeswap token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    CUBETESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    CUBETESTNET,
    '0x3d8f2Ada8e97e4eF19e4ccBf6ec1Ca52900406aA',
    18,
    'USDT',
    'TETHER USD',
    'https://pancakeswap.finance/',
  ),
  usdc: new Token(
    CUBETESTNET,
    '0xb448B701807E644f141a4E4a269aD2F567526505',
    18,
    'USDC',
    'USD Coin',
    'https://pancakeswap.finance/',
  ),
} as const)

export const ethereumTokens = defineTokens({
  wbnb: new Token(ETHEREUM, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH', 'Wrapped ETHER'),
  des: new Token(
    ETHEREUM,
    '0x634239cfA331Df0291653139d1a6083B9cf705e3',
    18,
    'DES',
    'Despace Protocol Token',
    'https://despace.io/',
  ),
  uni: new Token(ETHEREUM, '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', 18, 'UNI', 'Uniswap'),
  btc: new Token(ETHEREUM, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'BTC', 'Wrapped BTC'),
  usdt: new Token(ETHEREUM, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'TETHER USD'),
  usdc: new Token(ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin'),
  dai: new Token(ETHEREUM, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 6, 'USDC', 'USD Coin'),
  busd: new Token(ETHEREUM, '0x4Fabb145d64652a948d72533023f6E7A623C7C53', 18, 'BUSD', 'Wrapped BUSD'),
  usdm: new Token(ETHEREUM, '0xbbAec992fc2d637151dAF40451f160bF85f3C8C1', 18, 'USDM', 'USD Mapped Token'),
  frax: new Token(ETHEREUM, '0x853d955aCEf822Db058eb8505911ED77F175b99e', 18, 'FRAX', 'Frax'),
} as const)

export const tokenByChain: { [chainId in ChainId]?: any } = {
  [MAINNET]: mainnetTokens,
  [TESTNET]: testnetTokens,
  [MATIC]: maticTokens,
  [MUMBAI]: mumbaiTokens,
  [CUBE]: cubeTokens,
  [CUBETESTNET]: cubeTestnetTokens,
  [ETHEREUM]: ethereumTokens,
}

const tokens = () => {
  if (CHAIN_ID) {
    return { ...mainnetTokens, ...tokenByChain[CHAIN_ID] }
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
