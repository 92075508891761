import React, { useState } from 'react'
import Button from '../button/Button'
import styles from './ToggleButton.module.scss'

const ToggleButton = ({ setState }) => {
  const [toggle, setToggle] = useState<boolean>(false)
  const handleToggle = () => {
    setToggle(!toggle)
    setState(!toggle)
  }
  return (
    <div className={styles.button} onClick={handleToggle} data-active={toggle}>
      <div className={`${!toggle && styles.activeBtn}`}></div>
      <div className={`${toggle && styles.activeBtn}`}></div>
    </div>
  )
}

export default ToggleButton
