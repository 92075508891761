import { createReducer } from '@reduxjs/toolkit'
import {
  updateStateNfts,
  toogleCollectionModal,
  saveNftPayload,
  fetchCollection,
  addUserCollections,
  updateMetaData,
  updateNftRes,
  updateNftFilters,
} from './actions'
import { AddCollectionPayload, NftState } from './types'

const initialState: NftState = {
  nfts: [],
  nftsMeta: {
    page: 1,
    limit: 15,
    count: 0,
  },
  featuredNfts: [],
  featuredNftsMeta: {
    page: 1,
    limit: 15,
    count: 0,
  },
  collections: [],
  collectionsMeta: {
    page: 1,
    limit: 15,
    count: 0,
  },
  userCollections: [],
  userCollectionsMeta: {
    page: 1,
    limit: 15,
    count: 0,
  },
  pendingNft: {
    collectionId: '',
  },
  showCollectionModal: {
    dialog: false,
    nft: {
      collectionId: '',
      contractAddress: '',
      createdAt: '',
      name: '',
      ownerAddress: '',
      royalty: 0,
      symbol: '',
      txHash: '',
      updatedAt: '',
      uri: '',
      uuid: '',
      quantity: 0,
      price: 0,
      collectionName: '',
      description: '',
      coin: '',
    },
  },
  nftFilters: {
    status: 'ALL',
    money: 'BNB',
    min: null,
    max: null,
  },
  updatedNft: {},
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateStateNfts, (state, action) => {
      if (action.payload.action === 'update') {
        state.nfts = [...state.nfts, ...action.payload.data]
      } else {
        state.nfts = action.payload.data
      }
    })
    .addCase(toogleCollectionModal, (state, action) => {
      if (action.payload) {
        state.showCollectionModal = action.payload.data
      }
    })
    .addCase(fetchCollection, (state, action) => {
      state.collections = action.payload.data
    })
    .addCase(addUserCollections, (state, action) => {
      state.userCollections = action.payload.data
    })
    .addCase(saveNftPayload, (state, action) => {
      state.pendingNft = action.payload.data
    })
    .addCase(updateMetaData, (state, action) => {
      const { module, payload } = action.payload.data
      state[module] = payload
    })
    .addCase(updateNftRes, (state, action) => {
      state.updatedNft = action.payload.data
    })
    .addCase(updateNftFilters, (state, action) => {
      state.nftFilters = action.payload.data
    })
})
