import { NETWORK_URLS } from '@/config'
import { CHAIN_ID } from '@/config/constants/networks'
import sample from 'lodash/sample'
import getChainID from './getChainID'

if (
  process.env.NODE_ENV !== 'production' &&
  (!process.env.NEXT_PUBLIC_NODE_1)
) {
  throw Error('One base RPC URL is undefined')
}

// Array of available nodes to connect to
export const nodes = Object.values(NETWORK_URLS)

const getNodeUrl = (chainId = getChainID()) => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the NEXT_PUBLIC_NODE_PRODUCTION from env file
  
  return NETWORK_URLS[chainId]
}


export default getNodeUrl
