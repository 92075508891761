import { Box, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'

import { getBlockExplorerLink } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'
import { Button } from '@/shared'
import getChainID from '@/utils/getChainID'
import { ETHERBYCHAIN, RPC_SCAN_LABELS } from '@/config'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import useUnstoppableDomain from '@/hooks/useUnstoppableDomain'
import QuestionHelper from '@/components/QuestionHelper'
import { userDomainName } from '@/state/user/hooks'
import { useEffect } from 'react'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.des.address)
  const { logout } = useAuth()
  const { getUserDomain } = useUnstoppableDomain()

  useEffect(() => {
    getUserDomain()
    return () => {
      getUserDomain()
    }
  }, [account])

  const domain = userDomainName()
  const chainID = getChainID()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="1.2rem" textTransform="uppercase" fontWeight="bold" mb="0.8rem">
        {domain ? t('Your Domain name') : t('Your Address')}
      </Text>
      <CopyAddress account={account} displayValue={domain ? domain : ''} mb="2.4rem" />
      {hasLowBnbBalance && (
        <Message variant="warning" mb="2.4rem">
          <Box>
            <Text fontWeight="bold">{t(`${ETHERBYCHAIN[chainID]} Balance Low`)}</Text>
            <Text as="p">{t(`You need ${ETHERBYCHAIN[chainID]} for transaction fees.`)}</Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="textSubtle">{t(`${ETHERBYCHAIN[chainID]} Balance`)}</Text>
        {fetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="2.2rem" width="6rem" />
        ) : (
          <Text>{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="2.4rem">
        <Text color="textSubtle">{t('DES Balance')}</Text>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="2.2rem" width="6rem" />
        ) : (
          <Text>{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="2.4rem">
        <LinkExternal href={getBlockExplorerLink(account, 'address')}>
          {t(`View on ${RPC_SCAN_LABELS[chainID]}`)}
        </LinkExternal>
      </Flex>
      {!domain && (
        <Flex alignItems="center" justifyContent="start" mb="2.4rem">
          <LinkExternal href="https://unstoppabledomains.com/auth">{t(`Create a custom domain`)}</LinkExternal>
          <QuestionHelper
            text={t(
              'Create and showcase a custom domain using unstoppable domain service to personalise your crypto experience',
            )}
            placement="top-start"
            ml="0.4rem"
          />
        </Flex>
      )}

      <Button type="purple-400" style={{ width: '100%' }} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
