import '@/styles/index.scss'
import BigNumber from 'bignumber.js'
import { Nprogress } from '@/shared'
import { PreLoader } from '@/shared/loaders'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useInactiveListener } from 'hooks/useInactiveListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import { Fragment, useEffect, useState, useRef } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Providers from '../Providers'
import useChainUpdater from '@/hooks/useChainUpdater'
import useGasPriceUpdater from '@/hooks/useGasPriceUpdater'
import { storeRefId, addUserReferral } from '@/utils/referralHelpers'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { NetworkModal } from '@/shared/modals'
import { useNetworkModalManager } from '@/state/user/hooks'
import { useCollections } from '@/state/nft/hooks'
import Script from 'next/script'

// Slick Carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useInactiveListener()
  useSentryUser()
  useChainUpdater()
  useGasPriceUpdater()
  return null
}

const usePreviousRoute = () => {
  const { asPath } = useRouter()

  const ref = useRef<string | null>(null)

  useEffect(() => {
    ref.current = asPath
  }, [asPath])

  return ref.current
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const router = useRouter()
  const [showNetworkModal] = useNetworkModalManager()
  const previousRoute = usePreviousRoute()
  const { account } = useActiveWeb3React()
  useCollections()

  storeRefId(router)

  // Add referral when user connects wallet
  useEffect(() => {
    const addNewRef = async () => {
      await addUserReferral(account)
    }
    return () => {
      addNewRef()
    }
  }, [account])

  // Use effect to either persist the ref query attribute
  useEffect(() => {
    const setRouteRef = () => {
      if (localStorage.getItem('refID') && previousRoute?.includes('ref')) {
        router.push({ query: { ref: localStorage.getItem('refID') } }, undefined, { shallow: true })
      }
    }
    setRouteRef()

    return () => {
      setRouteRef()
    }
  }, [router.pathname])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <ProductionErrorBoundary>
      <Layout>
        <Nprogress />
        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />

        <Script strategy="lazyOnload" id="google-analytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
                `}
        </Script>
        {showNetworkModal && typeof window !== 'undefined' && <NetworkModal />}
        {!isLoaded ? <PreLoader /> : <Component {...pageProps} />}
      </Layout>
      <ToastListener />
    </ProductionErrorBoundary>
  )
}

export default MyApp
