import { ChainId, Currency, Token, WETH } from "@pancakeswap/sdk"

export default function convertToNativeTokenFromETH(currency: Currency, chainId?: ChainId): Currency {
    if (chainId && currency === Currency.ETHER) {
      if ([137, 80001].includes(chainId)) return new Token(chainId, WETH[chainId].address, 18, 'MATIC', 'MATIC')
      if ([56, 97].includes(chainId)) return new Token(chainId, WETH[chainId].address, 18, 'BNB', 'BNB')
      if ([1818, 1819].includes(chainId)) return new Token(chainId, WETH[chainId].address, 18, 'CUBE', 'CUBE')
      if ([1].includes(chainId)) return new Token(chainId, WETH[chainId].address, 18, 'ETH', 'ETH')
    }
  
    return currency
}