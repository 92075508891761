import React, { useState, useEffect, useCallback } from 'react'
import Button from '@/shared/button/Button'
import Image from 'next/image'
import { setupNetworkWithChain } from '@/utils/wallet'
import { connectorsByName } from '@/utils/web3React'
import { connectorLocalStorageKey } from '@pancakeswap/uikit'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { CHAIN_ID } from '@/config/constants/networks'
import { ChainId as CHAIN_ID_CHECK } from '@pancakeswap/sdk'
import { CHAIN_ID_KEY, NETWORK_DETAILS, SUPPORTED_CHAIN_IDS } from '@/config'
import isWindow from '@/utils/isWindow'
import { useRouter } from 'next/router'
import styles from './NetworkModal.module.scss'
import { useNetworkModalManager } from '@/state/user/hooks'
import { useLocalStorage } from 'usehooks-ts'
import getChainID from '@/utils/getChainID'

export type Props = {
    close: () => void
}

const NetworkModal = () => {
    const [selectedChainId, setSelectedChainId] = useState<number>(parseInt(CHAIN_ID))
    const { chainId, activate } = useActiveWeb3React()
    const [, hideModal] = useNetworkModalManager()

    const router = useRouter()
    const validChainIds = Object.keys(CHAIN_ID_CHECK).map(function (type) {
        return CHAIN_ID_CHECK[type]
    })

    const networkId = router.query.networkId

    useEffect(() => {
        if (chainId) {
            setSelectedChainId(chainId)
        }
    }, [chainId])

    useEffect(() => {
        if (
            networkId &&
            typeof networkId === 'string' &&
            validChainIds.includes(parseInt(networkId)) &&
            !sessionStorage.getItem('networkId')
        ) {
            onOptionClicked(parseInt(networkId))()

            // add the networkId param to session storage so that this useEffect doesnt try to switch the user back after they have been switched the first time
            // Unless they open a new tab
            sessionStorage.setItem('networkId', networkId)
        }
    }, [networkId])

    const onOptionClicked = (_selectedChainId: number) => async () => {
        setSelectedChainId(_selectedChainId)
        hideModal()
        if (isWindow()) {
            window.localStorage.setItem(CHAIN_ID_KEY, _selectedChainId.toString());
            console.log("_selectedChainId ", _selectedChainId);
            
            // setChainId(_selectedChainId.toString())
            const connectorID = window.localStorage.getItem(connectorLocalStorageKey) || 'injected'
            const connectorOrGetConnector = connectorsByName[connectorID]
            const connector =
                typeof connectorOrGetConnector !== 'function' ? connectorsByName[connectorID] : await connectorOrGetConnector()
            const provider = typeof connector != 'function' && connector ? await connector.getProvider() : window.ethereum
            await setupNetworkWithChain(provider, _selectedChainId)
            activate(connector)
        } else {
            await setupNetworkWithChain(null, _selectedChainId)
        }
    }

    useEffect(() => {
        document.addEventListener('click', hideModal)
        return () => {
            document.removeEventListener('click', hideModal)
        }
    }, [])


    return (
        <div className={styles.networkModal}>
            <div className={styles.networkModal_container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.networkModal_row}>
                    <h3 className={styles.networkModal_title}>Select network </h3>
                    <Button className={styles.networkModal_closeModal} type="transparent" onClick={hideModal}>
                        <span></span>
                        <span></span>
                    </Button>
                </div>
                <div className={styles.networkModal_body}>
                    <p>Choose the network you will be interacting with</p>
                    <div className={styles.network_list}>
                        {SUPPORTED_CHAIN_IDS.map((key: CHAIN_ID_CHECK, i: number) => {
                            return (
                                <div className={styles.select_networkButton}
                                    title={NETWORK_DETAILS[key].label}
                                    key={i}
                                    onClick={onOptionClicked(key)}
                                >
                                    <div className={styles.list_item}>
                                        <img src={NETWORK_DETAILS[key].icon} alt="Switch Network" className={styles.network_icon} />
                                        <div className={styles.network_label}>{NETWORK_DETAILS[key].label}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetworkModal