import { MediaQueries, Breakpoints, Spacing, Shadows, Radii, ZIndices } from "./types";

export const breakpointMap: { [key: string]: number } = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
  xxl: 1200,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`);

const mediaQueries: MediaQueries = {
  xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
  nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
};

export const shadows: Shadows = {
  level1: "0px 0.2rem 1.2rem -0.8rem rgba(25, 19, 38, 0.1), 0px 0.1rem 0.1rem rgba(25, 19, 38, 0.05)",
  active: "0px 0px 0px 0.1rem #0098A1, 0px 0px 0.4rem 0.8rem rgba(31, 199, 212, 0.4)",
  success: "0px 0px 0px 0.1rem #31D0AA, 0px 0px 0px 0.4rem rgba(49, 208, 170, 0.2)",
  warning: "0px 0px 0px 0.1rem #ED4B9E, 0px 0px 0px 0.4rem rgba(237, 75, 158, 0.2)",
  focus: "0px 0px 0px 0.1rem #7645D9, 0px 0px 0px 0.4rem rgba(118, 69, 217, 0.6)",
  inset: "inset 0px 0.2rem 0.2rem -0.1rem rgba(74, 74, 104, 0.1)",
  tooltip: "0px 0px 0.2rem rgba(0, 0, 0, 0.2), 0px 0.4rem 1.2rem -0.8rem rgba(14, 14, 44, 0.1)",
};

const spacing: Spacing = [0, 4, 8, 16, 24, 32, 48, 64];

const radii: Radii = {
  small: "0.4rem",
  default: "1.6rem",
  card: "2.4rem",
  circle: "50%",
};

const zIndices: ZIndices = {
  ribbon: 9,
  dropdown: 10,
  modal: 100,
};

export default {
  siteWidth: 1200,
  breakpoints,
  mediaQueries,
  spacing,
  shadows,
  radii,
  zIndices,
};
