import { useState, useEffect } from 'react'
import styles from './NftBidModal.module.scss'
import Image from 'next/image'
import { chains } from '@/mock/chains'
import { Nfts } from '@/state/types'
import truncateHash from '@/utils/truncateHash'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { NETWORK_DETAILS } from '@/config'
import { AuctionTradeDetails } from '@/state/nft/types'
import { useMarketPlace } from '@/state/nft/hooks'
import { formatUnits, parseUnits } from '@ethersproject/units'
interface Network {
  label: string
  icon: string
  active: boolean
}

const NftBidModal = ({
  nft,
  hideDialog,
  auctionTrade,
  auctionTimeLeft,
}: {
  nft: Nfts
  hideDialog: () => void
  auctionTrade: AuctionTradeDetails
  auctionTimeLeft: { endsIn: string; isFixedTime: boolean; startsIn: string }
}) => {
  const [amount, setAmount] = useState<{ value: number; min: number }>({
    value: 0,
    min: 0,
  })
  const [selectedChain, setSelectedChain] = useState<string>('BNB')
  const [menuOpen, setMenuOpen] = useState<Boolean>(false)
  const [loading, setLoading] = useState<Boolean>(false)
  // const bidExpirations = ['7 Days', '14 Days', '30 Days', '90 Days']
  const { account, chainId } = useActiveWeb3React()
  const { getAmountForNextBid, makeBid } = useMarketPlace()

  const changeActive = (network: Network) => {
    setSelectedChain(network.label)
    setMenuOpen(false)
  }

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000)
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var hour = a.getHours()
    var min = a.getMinutes()
    var sec = a.getSeconds()
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
    return time
  }

  const getNetworkIcon = (): string => {
    return menuItems.find((item: Network) => item.label === selectedChain)?.icon || '/imgs/svgs/icon-ethereum.svg'
  }
  const menuItems = [
    {
      label: 'BNB',
      icon: '/svgs/bsc.svg',
      active: true,
    },
    {
      label: 'DES',
      icon: '/svgs/des.svg',
      active: true,
    },
  ]
  const changeAmount = (e: any) => {
    setAmount({ ...amount, value: e.target.value })
  }
  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(!menuOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const placeBid = async () => {
    setLoading(true)
    try {
      const res = await makeBid(
        nft.auctionId,
        auctionTrade.moneyType === 0 ? parseUnits(amount.value.toString(), 'ether') : 0,
        auctionTrade.moneyType === 0 ? 0 : parseUnits(amount.value.toString(), 'ether'),
        auctionTrade.moneyType,
        nft.tokenStandard,
      )
      await res.wait(1)
      hideDialog()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const getMinBidAmount = async () => {
      try {
        const amount = await getAmountForNextBid(nft.auctionId, nft.tokenStandard)
        const parsedAmount = +formatUnits(amount.toString())
        setAmount({ value: parsedAmount, min: parsedAmount })
      } catch (error) {
        console.log(error)
      }
    }
    getMinBidAmount()
  }, [])

  return (
    <div className={styles.bid}>
      <div className={styles.bid_container}>
        <div className={styles.bid_heading}>
          <div>
            <h6>Place a bid</h6>
            <p>
              You are about to place a bid for <span>{nft.name}</span> from <span>{nft.collectionName}</span>
            </p>
          </div>
          <button onClick={() => hideDialog()}>
            <Image layout="fill" src="/svgs/icon-close.svg" alt="close" />
          </button>
        </div>

        <div className={styles.bid_user}>
          <div className={styles.bid_user_wrapper}>
            <div className={styles.bid_user_chain}>
              <Image layout="fill" src={NETWORK_DETAILS[chainId]?.icon} alt="ETH" />
            </div>

            <div>
              <h6>{account ? truncateHash(account) : '0x-----'}</h6>
              <p>{NETWORK_DETAILS[chainId]?.label}</p>
            </div>
          </div>
          {account ? (
            <div className={`${styles.bid_chip} ${styles.bid_chip_success}`}>Connected</div>
          ) : (
            <div className={`${styles.bid_chip} ${styles.bid_chip_error}`}>Disconnected</div>
          )}
        </div>
        <div className={styles.bid_amount}>
          <p>Your bid</p>
          <div>
            <div className={styles.bid_amount_input_wrapper}>
              <input
                type="tel"
                name="amount"
                id=""
                value={amount.value}
                onInput={(e) => changeAmount(e)}
                size={amount.value ? amount.value.toString().length : 1}
              />
              <h6>
                <span>
                  ($
                  {selectedChain === 'BNB'
                    ? (amount.value * 307.24).toFixed(3)
                    : (amount.value * 0.001862).toFixed(3)}{' '}
                  )
                </span>
              </h6>
            </div>
            <div className={styles.bid_amount_cryptowrapper}>
              <button onClick={toggleMenu}>
                <div className={styles.bid_amount_crypto}>
                  <Image src={getNetworkIcon()} layout="fill" alt="icon" />
                </div>
                <span>{selectedChain}</span>
                <Image src="/svgs/chevron-bottom.svg" layout="fixed" height="15rem" width="15rem" alt="icon" />
              </button>
              {menuOpen && (
                <div className={styles.bid_amount_menu}>
                  {menuItems.map((network: Network, i: number) => {
                    return (
                      <div
                        key={i}
                        className={`${styles.bid_amount_menu_item}
                  ${network.label === selectedChain && styles.bid_amount_menu_item_active}`}
                        onClick={() => changeActive(network)}
                      >
                        <Image
                          src={network.icon}
                          layout="fixed"
                          height="16rem"
                          width="16rem"
                          alt={network.label}
                          className={styles.bid_amount_menu_icon}
                        />
                        <span>{network.label}</span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {amount.min > amount.value && (
          <p className={styles.bid_error}>
            You cannot bid less than {amount.min} {auctionTrade?.moneyType === 0 ? 'DES' : 'BNB'}
          </p>
        )}
        {/* <div className={styles.bid_expiration}>
          <p>Bid Expiration</p>
          <div className={styles.bid_expiration_button}>
            {bidExpirations.map((expiration, i) => {
              return (
                <button
                  key={i}
                  className={`${styles.bid_button} ${activeExpiration === expiration ? styles.bid_button_active : ''}`}
                  onClick={() => setActiveExpiration(expiration)}
                >
                  {expiration}
                </button>
              )
            })}
          </div>
        </div> */}
        <div className={styles.bid_details}>
          {auctionTimeLeft?.isFixedTime && <p>Expiration date: {timeConverter(auctionTrade?.endTime)}</p>}

          <div className={styles.bid_details_item}>
            <h6>Total Interest</h6>
            <h5>
              <span>0.0923%</span> ($0.21229)
            </h5>
          </div>
          <div className={styles.bid_details_item}>
            <h6>Bidding Amount</h6>
            <h5>
              <span> 0.01319%</span> ($0.030)
            </h5>
          </div>
          <div className={styles.bid_details_item}>
            <h6>Service Fee</h6>
            <h5>
              <span>$5.4 </span> (Standard)
            </h5>
          </div>
          <button onClick={placeBid} disabled={amount.min > amount.value || loading === true}>
            {loading ? <div className="loading-spinner" /> : 'Place a bid'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NftBidModal
